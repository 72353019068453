import Vue from 'vue'
import App from './App.vue'
import './plugins/bootstrap-vue.js'
import store from './store'
import router from './router'
import VueCookies from 'vue-cookies'


Vue.config.productionTip = false
Vue.prototype.$settings = Vue.observable({ hebrew: true })
Vue.use(VueCookies)
Vue.use(router)

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')

setTimeout(() => {
  const viewportResolution = `${window.innerWidth}x${window.innerHeight}`;
  const screenResolution = `${screen.width}x${screen.height}`;
  const userAgent = navigator.userAgent;

  const data = `${viewportResolution}:::${screenResolution}:::${userAgent}`;
  navigator.sendBeacon('/page-size', data)
}, 3000)
