<template>
  <div class="about-page">
      <about-us></about-us>
  </div>
</template>

<script>
import AboutUs from "@/components/aboutUs"

export default {
  name: 'About',
  components: { 
    AboutUs
  },
  data () {
    return {
     
    }
  },
  computed: {
    hebrew () {
      return this.$settings.hebrew
    }
  },    
  mounted () {
    document.getElementById('top-actions').setAttribute("style", "visibility:inherit;")
    document.getElementById('navigation').setAttribute("style", "visibility:inherit;")
  }  
}
</script>

