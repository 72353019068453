<template>
  <div class="developer-information bg-background pb-5">
    <div
      class="mb-4 description d-flex justify-content-center text-center text-white align-items-center"
    >
      <h1>{{ hebrew ? "Dicta LM" : "Dicta LM" }}</h1>
    </div>
    <b-container dir="rtl" class="text-right">
      <div class="d-flex justify-content-around">
        <a href="https://dicta.org.il"
          ><img src="@/assets/llm/image1.jpg" class="logo-item w-auto"
        /></a>
        <a href="https://nnlp-il.mafat.ai"
          ><img src="@/assets/llm/image5.jpg" class="logo-item w-auto"
        /></a>
        <a href="https://www.iahlt.org"
          ><img src="@/assets/llm/image3.jpg" class="logo-item w-auto"
        /></a>
      </div>

      <section class="intro bg-white px-3 py-4 mx-auto mb-3 mt-4 shadow">
        <p class="text-center mb-0 mt-4">
          <a href="https://dicta.org.il">מרכז דיקטה</a>, בשיתוף
          <a href="https://nnlp-il.mafat.ai/">מפא"ת / התכנית הלאומית ל-NLP</a>
          והאיגוד הישראלי לטכנולוגיות שפת אנוש (<a href="https://www.iahlt.org/"
            >iahlt.org</a
          >), שמחים לשחרר לכם את:
        </p>
        <h2 class="text-center my-3 font-weight-bold">
          Dicta-LM 2.0 - מודל שפה גנרטיבי גדול בעברית, פתוח, וחזק!
        </h2>
        <div class="blue-sep"></div>

        <div class="intro">
          <p>
            <b>Dicta-LM 2.0</b> הוא מודל שפה גדול גנרטיבי (LLM) חדש ופורץ דרך
            בעברית, המודלים זמינים כעת להורדה חופשית ושימוש ללא הגבלה. המודל הוא אחד
            מה-LLM הטובים ביותר בעברית בקטגוריית מודלי שפה בגודל של עד 13
            מיליארד פרמטרים<sup>1</sup>.
          </p>
          <p>
            המודלים זמינים בהאגינגפייס לקהל הרחב, ברישיון Apache 2.0. גרסת הבסיס וגם
            גרסה ייעודית לצ'אט זמינות בצורה חופשית כאן:
            <a
              href="https://huggingface.co/collections/dicta-il/dicta-lm-20-661bbda397df671e4a430c27"
              >Dicta-LM 2.0 Collection</a
            >
          </p>
          <h2 class="text-center font-weight-bold">דמו:</h2>
          <div class="blue-sep"></div>
          <p>
            <a
              href="https://huggingface.co/spaces/dicta-il/dictalm2.0-instruct-demo"
              >דמו לצ'אט</a
            >: דמו של מודל המאומן עבור צ'אט.
          </p>
          <p>
            <a href="https://translate.dicta.org.il/">דמו לתרגום</a>: אנו גאים
            להציג אתר חדש לתרגום אוטומטית של אנגלית&lt;-&gt;עברית, המבוסס על
            מודל הבסיס, ושיש בו כדי לשקף את היכולות הלשוניות הטמונות במודל זה.
            ביצועי מודל התרגום עולים על ביצועי Google translate (אבלואציה
            בהמשך).
          </p>


          <p class="alert alert-secondary mx-auto border">
            <i class="fas fa-info-circle"></i>
            המודלים זמינים הן בדיוק מלא והן בדיוק של
            <span dir="ltr">4-bit</span>, בפורמט של AWQ או GPTQ (עם צריכת זיכרון של ~5GB).
            <br/>
            <i class="fas fa-info-circle"></i>
            המודלים גם זמינים לשימוש ישירות ב-LM Studio, GPT4All וגם עם llama.cpp. 
            <br/>
            <i class="fas fa-info-circle"></i>
                בנוסף, המודלים כבר הועלו לאתר Replicate לשימוש עם API: 
                <a href="https://shorturl.at/cmALM">מודל בסיס</a>,
                <a href="https://shorturl.at/jkry4">מודל צ'אט</a>. 
            </p>

          <p>
            <small> <sup>1</sup>בדיקות שבוצעו מפורטות בהמשך </small>
          </p>
        </div>
      </section>
      <section class="features bg-white px-3 py-4 mx-auto mb-3 shadow">
        <h2 class="text-center font-weight-bold">מאפיינים עיקריים:</h2>
        <div class="blue-sep"></div>
        <ul>
          <li>
            מבוסס על
            <a href="https://huggingface.co/mistralai/Mistral-7B-v0.1"
              >Mistral-7B-v0.1</a
            >
          </li>
          <li>
            טוקנייזר משופר לשפה העברית עם דחיסה משופרת עם ~2.7 טוקנים למילה בממוצע (שיפור של פי 2.1 ממיסטרל, ופי 2 מלמה-3)            
          </li>
          <li>
            המודל אומן על קלאסטר של Intel Gaudi 2, על סך הכל כ-190 מיליארד
            טוקנים, 50% בעברית ו-50% באנגלית
            <ul>
              <li>
                הקורפוס באנגלית נלקח מ-<a
                  href="https://huggingface.co/datasets/cerebras/SlimPajama-627B"
                  >SlimPajama</a
                >
              </li>
              <li>
                הקורפוס בעברית נלקח ממקורות שונים. כל התוכן בעברית הינו טקסט
                שמופיע באופן טבעי ועבר תהליכים אינטנסיביים של סינון, ניקוי והסרת
                כפילויות. אין שום תוכן סינתטי בכל הקורפוס.
              </li>
            </ul>
          </li>
          <li>
            מודל האינסטרקט אומן על פי המתכון של
            <a href="https://huggingface.co/HuggingFaceH4/zephyr-7b-beta"
              >Zephyr-7B-beta</a
            >
            - הוא עבר 3 אפוקים של אימון SFT, ואז עוד אפוק של אימון DPO. כל הדאטה
            למשימת הAlignment ישוחרר בשבועות הקרובים.
          </li>
        </ul>
        <p class="alert alert-secondary mx-auto border">
          <i class="fas fa-info-circle"></i>
          כלל הפרטים על איך ועל מה המודל אומן יפורסמו בשבועות הקרובים במסמך טכני
          באתר <a href="https://arxiv.org/">arxiv.org</a>.
        </p>
      </section>
      <section class="bg-white px-3 py-4 mx-auto mb-3 shadow">
        <h2 class="text-center font-weight-bold">אבלואציה למודל הבסיס:</h2>
        <div class="blue-sep"></div>

        <h3 class="font-weight-bold">בנצ'מארק בעברית למודלי LLM</h3>
        <p>
          לצורך אבלואציית המודל, אספנו מספר מדדים למשימות שונות בעברית. התוצר
          הוא leaderboard שאותו אנו מנגישים לקהילה בתקווה ליצר מדד אחיד להשוואה
          בין LLMs שונים בשפה העברית. לפי מדדים אלו המודל החדש עולה ביכולותיו על
          כל מודל פתוח אחר בעברית בגודל של עד כ-13 מיליארד פרמטרים.
        </p>
        <p>המדדים ב-leaderboard הם:</p>
        <ol>
          <li>
            <b>שאלות ותשובות (QA)</b>: השתמשנו בחלוקת הטסט של HeQ עם סך הכול
            1,436 דוגמאות. חישוב התוצאות נעשה באמצעות שיטת הניקוד tlnls שפורסמה
            ב-HeQ.
          </li>
          <li>
            <b>זיהוי רגש (סנטימנט)</b>: דגמנו כ-3,000 דוגמאות (1,000 מכל
            קטגוריה) מתוך דאטה סט של סנטימנט שפורסם ע״י מפאת. כדי לוודא את איכות
            הדוגמאות, הדיגום נעשה בעזרת בלשן. חישוב התוצאות נעשה ע״י דיוק
            (accuracy).
          </li>
          <li>
            <b>ווינוגראד</b>: השתמשנו בגירסה העברית לבנצ'מארק ווינוגראד הידוע
            שנוצר ע"י ד"ר ורד שוורץ, עם סך הכול 278 דוגמאות. המשימה הוגדרה
            כמשימה בינארית (בייסליין של 50%).
          </li>
          <li>
            <b>תרגום</b>: לקחנו באופן אקראי 1,000 דוגמאות של משפטים באורכים של
            30-40 מילים מתוך מאגר של משפטים מתורגמים (מאגר
            <a
              href="https://opus.nlpl.eu/NeuLab-TedTalks/corpus/version/NeuLab-TedTalks"
              >NeuLabs-TedTalks</a
            >). הבדיקה נעשה הן על תרגום מעברית לאנגלית ומאנגלית לעברית.חישוב
            התוצאות נעשה ע״י BLEU ואבלואציה אנושית (מוצגת בהמשך).
          </li>
        </ol>
        <p class="alert alert-secondary mx-auto border">
          <i class="fas fa-info-circle"></i>
          ה-leaderboard ופרטים נוספים על תהליך האבלואציה (כולל פרומפטים מדויקים)
          יונגשו בשבועות הקרובים וניתן יהיה להגיש מודלים חדשים לאבלואציה והוספה
          ללוח!
        </p>

        <img src="@/assets/llm/image2.png" class="mb-4 d-block mx-auto" />

        <p class="alert alert-secondary mx-auto border">
          נבקש לציין כי כלל הבדיקות שנערכו על מודל הבסיס נעשו תוך שימוש
          בפרומפטים מסוג Few-Shot.
        </p>
        <h3 class="font-weight-bold">אבלואציה אנושית של תרגום</h3>
        <p>
          לקחנו 1000 משפטים באנגלית ותרגמנו אותם לעברית על ידי המודל שלנו וגם על
          ידי גוגל תרגום. הצגנו את המשפטים המתורגמים לבלשן ב-2 טורים לא מסומנים
          (כלומר, "טור א" ו-"טור ב" בלתי מזוהים), וביקשנו ממנו לציין עבור כל
          משפט איזה תרגום הוא מעדיף. להלן התוצאות:
        </p>
        <div class="table-responsive">
          <b-table
            dir="ltr"
            class="text-center mx-auto"
            striped
            hover
            :items="[
              {
                totalTagged: '1,000',
                'DictaLM-2.0': '742',
                googleTranslate: '222',
                noPreference: '36',
              },
            ]"
          ></b-table>
        </div>
      </section>
      <section class="bg-white px-3 py-4 mx-auto mb-3 shadow">
        <h2 class="text-center font-weight-bold">אבלואציה למודל הצ'אט:</h2>
        <div class="blue-sep"></div>
        <p>
          בנוסף לבדיקות במסגרת ה-leaderboard, מודל הצ׳אט נבדק על יכולת הסיכום
          שלו לצורך הערכת היכולות הג׳נרטיביות שלו.
        </p>
        <p>
          גם על מודל הצ'אט הרצנו את הבדיקות שהרצנו על מודלי הבסיס (בדיקות
          few-shot), ניתן לראות תוצאות בטבלה למעלה.
        </p>
        <p></p>
        <p>
          לשם כך יצרנו מאגר של 75 כתבות אקראיות, שסוכמו ע"י בני אדם. לצורך
          אבלואציה, השוונו את איכות הסיכומים של מודלים מוכרים והמודל שלנו בעזרת
          GPT-4 (על פי מתכון דומה למתכון שמוצע ע"י OpenAI
          <a
            href="https://cookbook.openai.com/examples/evaluation/how_to_eval_abstractive_summarization"
            >כאן</a
          >), ע״פ המדדים הבאים:
        </p>

        <p>בחנו את תוצאות התרגום על פי 4 קטגוריות:</p>
        <ol>
          <li><b>רלוונטיות</b> (1-10): בחירת תוכן חשוב מתוך המקור.</li>
          <li><b>קוהרנטיות</b> (1-10): האיכות הקולקטיבית של כל המשפטים.</li>
          <li>
            <b>עקביות</b> (1-10): ההתאמה העובדתית בין הסיכום למקור המסוכם.
          </li>
          <li>
            <b>רהיטות</b> (1-10): איכות הסיכום במונחים של דקדוק, איות, פיסוק,
            בחירת מילים, ומבנה משפט.
          </li>
        </ol>
        <p>תוצאות</p>
        <img
          class="table-img mb-4 mx-auto d-block"
          src="@/assets/llm/image4.png"
        />

        <p>
          המודל החדש עולה על המודלים האחרים הקיימים בגודל דומה, ומגיע לתוצאות
          תחרותיות אל מול מודלים גדולים בהרבה.
        </p>
      </section>
      <section class="bg-white px-3 py-4 mx-auto mb-3 shadow">
        <h2 class="text-center font-weight-bold">סיכום:</h2>
        <div class="blue-sep"></div>
        <p>
          אנחנו שמחים לשחרר לקהל הרחב את Dicta-LM 2.0 - מודל שפה גנרטיבי חזק
          בעברית, לשימוש חופשי (מחקרי ומסחרי). אנחנו ממשיכים לאמן ולשפר את
          המודלים, ומתכוונים לפרסם עוד פרטים וכלים על מודלי שפה בעברית בשבועות
          הקרובים.
        </p>
        <p class="text-center">
          אנו גאים לקדם את יכולות ה-NLP בעברית, ושמחים להזדמנות לשתף את התוצרים
          שלנו עם הקהילה.
        </p>
      </section>
      <div
        class="alert alert-primary mx-auto border border-primary text-center"
      >
        <p class="mb-0">
          אנחנו רוצים להודות לאינטל לאבס ישראל צוות NLP: פטר איזאק, דניאל
          פליישר, משה ברציאנסקי ומשה וסרבלט
        </p>
        <hr />
        <p class="mb-0">
          שוחרר על ידי DICTA, בשיתוף מפא"ת והאיגוד הישראלי
          לטכנולוגיות שפת אנוש (iahlt.org).
        </p>
        <p class="mb-0">
            לשאלות ניתן לפנות לשאלתיאל שמידמן, מתכנת ראשי במעבדת למידה עמוקה במרכז דיקטה, במייל: shaltiel@dicta.org.il
        </p>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  name: "DictaLM",
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
  },
}
</script>
<style scoped lang="scss">
.logo-item {
  max-height: 100px;
}
li {
  margin-bottom: 10px;
}
.alert,
section {
  border-radius: 8px;
}
@media (min-width: 992px) {
  .developer-information {
    table,
    .alert,
    section {
      max-width: 85%;
      img.table-img {
        max-width: 75% !important;
      }
    }
  }
}
</style>
