<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="home">
    <div class="description d-flex flex-column justify-content-center text-center text-white">
      <h1 class="mb-3">{{ hebrew ? 'דיקטה' : 'Dicta' }}</h1>
    </div>
    <h2 class="tools-heading text-center pb-3">
      <span v-if="hebrew">
        כלים דיגיטליים<br>
        לעיבוד טקסטים בעברית
      </span>
      <span v-else>
        Analytical tools<br>
        for Hebrew texts
      </span>
    </h2>
    <div class="blue-sep"></div>
    <b-container class="text-center" style="max-width: 727px;">
      <span v-if="hebrew">
        עמותת דיקטה מספקת לכל המתעניינים בטקסטים בעברית כלים המבוססים על האלגוריתמים
        המתקדמים ביותר של למידת מכונה ועיבוד שפה טבעית.
        <a href="/about">קראו עוד</a>
      </span>
      <span v-else>
        Dicta provides Hebrew text tools based on
the most advanced algorithms of machine learning and natural language processing. <a href="/about">Read more</a>
      </span>
      <b-btn class="bg-transparent shadow-none mx-auto vid-btn d-block mt-4 mb-5" id="bannerVideoLink"
        @click="showVideo = true; vidID = 'IqgKr6QWL30'">
        <i class="fa fa-youtube-play" aria-hidden="true"></i>
        {{ hebrew ? 'צפו בווידאו' : 'Watch video' }}
      </b-btn>
    </b-container>
    <div class="bg-background py-4 py-md-5">
      <b-container class="d-md-flex justify-content-between">
        <div class="pt-2">
          <h3 class="pb-2">{{ hebrew ? 'נקדן מהיר' : 'Nakdan Live' }}</h3>
          <p v-if="hebrew">ניקוד אוטומטי של טקסטים בעברית מודרנית, תוך כדי הקלדה.</p>
          <p v-else>Automatically add nikud (vocalization) to text as you type.</p>
          <a class="d-block pt-2" href="https://nakdanlive.dicta.org.il/">
            {{ hebrew ? 'התחילו לנקד' : 'Add nikud' }}
            <i v-if="hebrew" class="fa fa-arrow-left" aria-hidden="true"></i>
            <i v-else class="fa fa-arrow-right" aria-hidden="true"></i>
          </a>
        </div>
        <div class="tool-wrap text-center px-md-4 px-3 mt-4 mt-md-0">
          <img class="position-relative mx-md-3 mt-md-2" src="../assets/home/nakdan-live.png" alt="Nakdan Live"/>
        </div>
      </b-container>
      <b-container class="mt-3 mt-md-5 pt-3 pb-3"><hr/></b-container>
      <b-container class="d-md-flex justify-content-between mb-4 flex-row-reverse">

        <div class="pt-2">
          <h3 class="pb-2">{{ hebrew ? 'חיפוש בתנ״ך' : 'Bible Search' }}</h3>
          <p v-if="hebrew">חיפוש מילים וביטויים בתנ"ך באופן אינטואיטיבי, בלי צורך להתעסק בהבדלי איות והטיה.</p>
          <p v-else>Search the Bible intuitively, with no need to worry about alternate spellings, prefixes and suffixes.</p>
          <a class="d-block pt-2" href="https://search.dicta.org.il/">
            {{ hebrew ? 'התחילו לחפש' : 'Start search' }}
            <i v-if="hebrew" class="fa fa-arrow-left" aria-hidden="true"></i>
            <i v-else class="fa fa-arrow-right" aria-hidden="true"></i>
          </a>
        </div>
        <div class="tool-wrap text-center px-md-4 px-3 mt-4 mt-md-0">
          <img class="position-relative mx-md-3 mt-md-2" src="../assets/home/bible-search.png" 
          :alt="hebrew ? 'חיפוש בתנך' : 'bible-search'"/>
        </div>
      </b-container>
    </div>
    <video-modal v-model="showVideo" :videoId="vidID"></video-modal>
    <div class="text-center py-5">
      {{ hebrew ? 'רוצים להכיר כלים נוספים של דיקטה?' : 'Want to see other tools Dicta offers?' }}
      <br>
      <b-btn href="/tools" class="tools-btn mt-2">{{ hebrew ? 'לכל הכלים' : 'Dicta\'s tools' }}</b-btn>
    </div>
    <Updates></Updates>
  </div>
</template>

<script>

import Updates from "@/components/updates"
import VideoModal from '@/components/VideoModal'

export default {
  name: 'Home',
  components: {
    Updates,
    VideoModal
  },
  data() {
    return {
      showVideo: false,
      vidID: ''
    }
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    }
  },
  mounted() {
    document.getElementById('top-actions').setAttribute("style", "visibility:inherit;")
    document.getElementById('navigation').setAttribute("style", "visibility:inherit;")
  }
}
</script>

<style lang="scss" scoped>
.home {
  font-size: 18px;
  h3 {
    font-size: 23px;
    @media only screen and (max-width: 991px) {
      font-size: 18px;
      font-weight: bold;
    }
  }
  hr {
    border-color: #bfbfbf;
  }
  p {
    max-width: 330px;
  }
  img {
    width: 415px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
    border-radius: 8px;
  }
  .tool-wrap {
    max-width: 100%;    
    border-radius: 14px;
    background-color: #d6ecff;
    img {      
      top: 15px;
    }
  }
  .tools-btn {
    padding: 5px 21px 6px;
    border-radius: 3px;
    background-color: #ffa900;
    font-size: 18px;
  }
}
</style>