export const publications = [
    {      
      name: 'The Lehrhaus',
      date: '2020',
      link: 'https://thelehrhaus.com/commentary/torah-study-and-the-digital-revolution-a-glimpse-of-the-future/',
      text: 'Torah Study and the Digital Revolution: A Glimpse of the Future ',
      authors: 'Moshe Koppel and Avi Shmidman',
      category: 'Digital Humanities and Torah'
    },
    {
        name: 'Proceedings of the 58th Annual Meeting of the Association for Computational Linguistics: System Demonstrations',
        date: 'July 2020, 197-203',
        link: 'https://aclanthology.org/2020.acl-demos.23/',
        text: 'Nakdan: Professional Hebrew Diacritizer',
        authors: 'Avi Shmidman, Shaltiel Shmidman, Moshe Koppel, Yoav Goldberg',
        category: 'Nakdan'
    },
    {
        name: 'Findings of the Association for Computational Linguistics',
        date: 'EMNLP 2020, 3316-3326',
        link: 'https://aclanthology.org/2020.findings-emnlp.297/',
        text: 'A Novel Challenge Set for Hebrew Morphological Disambiguation and Diacritics Restoration',
        authors: 'Avi Shmidman, Josh Guedalia, Shaltiel Shmidman, Moshe Koppel, Reut Tsarfaty',
        category: 'Nakdan'
    },
    {
        name: 'Proceedings of the 17th Conference of the European Chapter of the Association for Computational Linguistics',
        date: 'May 2023, 849-864',
        link: 'https://aclanthology.org/2023.eacl-main.59',
        text: 'Do Pretrained Contextual Language Models Distinguish between Hebrew Homograph Analyses?',
        authors: 'Avi Shmidman, Cheyn Shmuel Shmidman, Dan Bareket, Moshe Koppel, Reut Tsarfaty',
        category: 'Nakdan'
    },
    {
        name: 'Israel Seminar of Computational Linguistics (ISCOL)',
        date: 'October 2021',
        link: 'https://www.dropbox.com/s/lmemlcw3013o1us/PosterIscol2021m.pdf?dl=0',
        text: 'Real-Time Hebrew Vocalization: Majority Rules, But Not Always',
        authors: 'Avi Shmidman, Cheyn Shmuel Shmidman, Shaltiel Shmidman, Joshua Guedalia, and Moshe Koppel',
        category: 'Nakdan'
    },
    {
        name: 'Digital Scholarship in the Humanities 35',
        date: '2019, 254-264',
        link: 'https://www.dropbox.com/s/rit47bg1dhqhhze/2019%20-%20Brill-Koppel-Shmidman--DSH--Synopsis.pdf?dl=0',
        text: 'FAST: Fast and Accurate Synoptic Texts',
        authors: 'Oran Brill, Moshe Koppel and Avi Shmidman',
        category: 'Synopsis'
    },
    {
        name: 'Journal of Data Mining and Digital Humanities, Special Issue on Computer-Aided Processing of Intertextuality in Ancient Languages',
        date: 'March 2018',
        link: 'https://arxiv.org/abs/1602.08715',
        text: 'Identification of Parallel Passages Across a Large Hebrew/Aramaic Corpus',
        authors: 'Avi Shmidman, Moshe Koppel, and Ely Porat',
        category: 'Parallels'
    },
    {
        date: 'Aug 2022',
        link: 'https://arxiv.org/abs/2208.01875',
        text: 'Introducing BEREL: BERT Embeddings for Rabbinic-Encoded Language',
        authors: 'Avi Shmidman, Joshua Guedalia, Shaltiel Shmidman, Cheyn Shmuel Shmidman, Eli Handel, Moshe Koppel',
        category: 'Large Language Models for Hebrew'
    },
    {
        date: 'Nov 2022',
        link: 'https://arxiv.org/abs/2211.15199',
        text: 'Large Pre-Trained Models with Extra-Large Vocabularies: A Contrastive Analysis of Hebrew BERT Models and a New One to Outperform Them All',
        authors: 'Eylon Gueta, Avi Shmidman, Shaltiel Shmidman, Cheyn Shmuel Shmidman, Joshua Guedalia, Moshe Koppel, Dan Bareket, Amit Seker, Reut Tsarfaty',
        category: 'Large Language Models for Hebrew'
    },
    {
        name: '#DHJewish - Jewish Studies in the Digital Age, ed. Gerben Zaagsma, et al',
        date: 'Berlin: DeGruyter, 2022, 335-348',
        link: 'https://www.dropbox.com/s/46wg57xnvhmb3p7/2022%20Avi%20Shmidman%20-%20Automatic%20Identification%20of%20Biblical%20Citations%20and%20Allusions%20--%20DHJewish%20-%20Jewish%20Studies%20in%20the%20Digital%20Age.pdf?dl=0',
        text: 'Automatic Identification of Biblical Citations and Allusions in Hebrew Texts',
        authors: 'Avi Shmidman',
        category: 'Citation Finder'
    },
    {
        name: 'Hebrew Linguistics 57',
        date: '2006, V-XVIII',
        link: 'https://www.dropbox.com/s/s3tlm6b5p55uaha/Koppel-Mughaz-Akiva--NewMethodsAttribution--HebrewLinguistics2006.pdf?dl=0',
        text: 'New Methods for Attribution of Rabbinic Literature',
        authors: 'Moshe Koppel, Dror Mughaz, and Navot Akiva',
        category: 'Stylistic Classification'
    },
    {
        name: 'Journal of the American Society for Information Science and Technology 64',
        date: '2013, 2256-2264',
        link: 'https://onlinelibrary.wiley.com/doi/10.1002/asi.22924',
        text: 'A generic unsupervised method for decomposing multi-author documents',
        authors: 'Navot Akiva and Moshe Koppel',
        category: 'Segmentation'
    },
    {
        name: 'Haifa University',
        nameHE: 'אוניברסיטת חיפה',
        date: '18/06/2023',
        link: "/publications/הרצאה_על_השס_המנוקד,_אונ'_חיפה,_כט_סיון_תשפג.pdf",
        text: 'The Vocalized Shas',
        textHE: 'השס המנוקד',
        authors: 'Yaaqov Loyfer',
        authorsHE: 'יעקב לויפר',
        category: 'Internal'
    },
   

]    