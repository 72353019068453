<template>
  <div class="dicta-common-header" :dir="hebrew ? 'rtl' : 'ltr'">
    <div class="d-none d-sm-block">
      <div class="top-bar">
      <span class="top-bar-left">
        <a :href="'http://dicta.org.il/' + (!hebrew ? '?lang=en' : '')">
          <span class="dicta">DICTA</span>
          <span class="dicta-tagline">
            {{
            hebrew ?
            'כלים דיגיטליים לעיבוד טקסטים בעברית' :
            '&nbsp;Analytical tools for Hebrew texts'
            }}
          </span>
        </a>
      </span>
        <span class="top-bar-right">
        <slot name="endContent"></slot>
        <span v-if="englishSupported">
          <a class="a-hover" @click="changeLanguage">{{ hebrew ? 'English' : 'עברית' }}</a>
          <span class="spacer">|</span>
        </span>
        <a id="contact-us" class="a-hover" @click="toggleContactModal">{{ hebrew ? 'צרו קשר' : 'Contact Us' }}</a>
        <span class="spacer">|</span>
        <a id="toolbar-button" class="a-hover" @click="toggleDropDown" @keyup.esc="toggleDropDown" ref="openTools">
          {{ hebrew ? 'הכלים של DICTA' : 'DICTA Tools'}} &nbsp;<i class="fas fa-caret-down"></i>
        </a>
      </span>
      </div>
      <contact-us :hebrew="hebrew" :siteUrl="siteUrl" v-model="showContactModal" @modal-closed="$emit('contact-open', false)"></contact-us>
    </div>
    <div>
      <div v-if="menuOpen" class="popup">
        <div class="popup-back" @click="toggleDropDown" ></div>
        <div class="tool-bar" @keyup.esc="toggleDropDown">
          <ul class="tool-list">
            <li class="tool" v-for="(tool, index) in tools" :key="index">
              <a @contextmenu="viewDropDown" :href="tool.hasOwnProperty('href') ? tool.href : (hebrew ? tool.hebHref : tool.engHref)" class="tool-link" target="_blank">
                <img class="logo" alt="logo" :src="tool.logo">
                <div class="d-inline-block">
                  <div><span class="title">{{hebrew ? tool.hebTitle : tool.engTitle}}</span></div>
                  <div class="subtitle">{{hebrew ? tool.hebSubtitle : tool.engSubtitle}}</div>
                </div>
              </a>
            </li>
          </ul>
          <tool-footer :hebrew="hebrew"></tool-footer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { tools } from './toolList'
import ContactUs from './contactUs'
import ToolFooter from './toolFooter'

export default {
  name: 'dicta-header',
  components: { ToolFooter, ContactUs },
  props: ['hebrew', 'englishSupported', 'siteUrl'],
  data () {
    return {
      menuOpen: false,
      tools,
      showContactModal: false
    }
  },
  computed: {
    
  },
  methods: {
    toggleContactModal () {
      this.menuOpen = false
      this.showContactModal = !this.showContactModal
      this.$emit('contact-open', this.showContactModal)
    },
    viewDropDown () {
      this.menuOpen = true
    },
    toggleDropDown () {
      this.menuOpen = !this.menuOpen
      this.$emit('menu-open', this.menuOpen)
    },
    dismissPopup (evt) {
      // delay the closing to allow the click event to occur
      setTimeout(() => {
        if (this.menuOpen && evt.target !== this.$refs['openTools']) {
          this.menuOpen = false
          document.body.removeEventListener('mouseup', this.dismissPopup)
        }
      }, 1)
    }
  },
  beforeDestroy () {
    this.dismissPopup()
  }
}
</script>
<style scoped lang="scss">
  .top-bar {
    font-size: 13px;
    line-height: 1.5;
    font-family: Roboto, Arimo, sans-serif;
    margin: 5px auto;
    max-width: 1140px;
    display: flex;
    justify-content: space-between;
  }

  .dicta {
    font-size: 14px;
  }

  .spacer {
    padding: 0 8px;
  }

  a {
    color: black;
    cursor: pointer;
  }
  .a-hover {
    display: inline-block;
    padding: 2px 4px;
    border-radius: 2px;
    margin: -2px -3px 0 -3px;
  }
  .a-hover:hover {
    background-color: #e3e3e3;
  }

  a:hover {
    color: black;
  }

  .popup {
    position: relative;
  }

  .popup-back {
    position: fixed;
    top: 50px;
    &.promotions-visible{
      top: 64px;
    }
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .11;
    background-color: black;
    z-index: 1000;
  }

  .tool-bar {
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.14);
    border: solid 1px #d8d8d8;
    box-sizing: border-box;
    background-color: white;
    z-index: 1039; /* behind dialogs */
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .tool-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 1170px;
    margin: 18px auto;
  }

  .tool {
    flex: 0 1 360px;
    list-style: none;
    margin: 10px 0;
    text-align: start;
  }

  /* holds both the logo and text */
  .tool-link {
    display: flex;
    color: black;
    text-decoration: none;
  }

  .logo {
    display: inline-block;
    margin: 2px 2px;
    height: 25px;
    width: 25px;
    background-color: white;
    align-self: flex-start;
  }

  a:hover .title {
    background-color: #d8d8d8;
    border-radius: 3px;
  }

  .title {
    font-size: 18px;
    padding: 2px 5px;
  }

  .subtitle {
    padding: 1px 5px;
    font-size: 13px;
  }
</style>
