<template>
  <div class="article flex-grow-1">
    <div
      class="description d-flex flex-column justify-content-center text-center text-white"
    >
      <h1 class="mb-3">
        {{
          hebrew
            ? "כלים לעריכת טקסטים עבריים ועיבודם"
            : "Analytical tools for Hebrew texts"
        }}
      </h1>
      <b-btn
        class="bg-transparent shadow-none mx-auto vid-btn"
        id="bannerVideoLink"
        @click="showVideo = true"
      >
        <i class="fa fa-youtube-play" aria-hidden="true"></i>
        {{ hebrew ? "צפו בווידאו" : "Watch video" }}
      </b-btn>
    </div>
    <video-modal v-model="showVideo" :videoId="'IqgKr6QWL30'"></video-modal>
    <div class="container py-5" v-if="post">
      <h1 class="text-center">{{ hebrew ? post.hebTitle : post.engTitle }}</h1>
      <div class="blue-sep"></div>
      <div class="news-text" v-if="hebrew" v-html="post.hebText"></div>
      <div class="news-text" v-else v-html="post.engText"></div>
    </div>
  </div>
</template>

<script>
import { updates } from "./updateList"
import VideoModal from "@/components/VideoModal"

export default {
  name: "Article",
  components: {
    VideoModal,
  },
  data() {
    return {
      showVideo: false,
    }
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    updates() {
      return updates
    },
    newsItem() {
      return this.$store.state.selectedNewsItem
    },
    post() {
      for (var i = 0; i <= this.updates.length; i++) {
        if (this.updates[i].href === this.newsItem) return this.updates[i]
      }
      return this.updates[i]
    },
  },
  created() {
    this.$store.commit("SET_NEWS_ITEM", this.$route.path.replace(/\//g, ""))
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.article .news-text {
  font-size: 20px;
  @media only screen and (max-width: 991px) {
    font-size: 16px;
    a {
      word-break: break-all;
    }
  }
}
</style>
