export const betaTools = [
 /*  {
    logo: require('../assets/logos/berel.png'),
    hebTitle: 'ממשק הדגמה למודל של BEREL',
    engTitle: 'New BEREL Demo Interface',
    hebText: 'ממשק הדגמה למודל של BEREL המשמש לעיבוד שפה רבנית וטקסטים רבניים-תורניים.',
    engText: 'Interpret Rabbinic language and Rabbinical-Torah text.',
    hebHref: 'https://berel.dicta.org.il/',
    hebButtonText: 'התחילו לנתח',
    engButtonText: 'Interpret Texts'

  },
     {
       logo: require('../assets/logos/transliterate.png'),
       hebTitle: 'תעתוק',
       engTitle: 'Transliterate',
       hebText: 'יצירת תעתוק לטקסט עברי באותיות לטיניות',
       engText: 'Transliterate Hebrew text to Latin letters.',  
       hebHref: 'https://transliterate.dicta.org.il/'      
     }, */
  {
    logo: require('../assets/logos/analytics.png'),
    hebTitle: 'אנליטיקס',
    engTitle: 'Analytics',
    hebText: 'שאילת שאילתות בטקסטים עבריים על נתונים כגון שכיחות, משקל, ערך על כל נטיותיו',
    engText: 'Query Hebrew texts to receive data such as frequencies, common forms of expression and more.',
    demoLink: 'https://www.youtube.com/watch?v=f9EwCWvJJ-Y',
    demoId: 'f9EwCWvJJ-Y',
    hebHref: 'https://analytics.dicta.org.il/',
    hebButtonText: 'נתחו',
    engButtonText: 'Analyze'
  },
  {
    logo: require('../assets/logos/analytics.png'),
    hebTitle: 'אנליטיקס לתנ"ך',
    engTitle: 'Bible Analytics',
    hebText: 'שאילת שאילתות בתנ״ך על נתונים כגון שכיחות, משקל, ערך על כל נטיותיו',
    engText: `
    Query Bible texts to receive data such as frequencies, 
    common forms of expression and more.
    `,
    demoLink: 'https://www.youtube.com/watch?v=f9EwCWvJJ-Y',
    demoId: 'f9EwCWvJJ-Y',
    hebHref: 'https://bibleanalytics.dicta.org.il',
    hebButtonText: 'נתחו',
    engButtonText: 'Analyze'
  },   
  {
    logo: require('../assets/logos/nakdan-add.png'),
    hebTitle: 'תוסף לכרום להוספת ניקוד למרשתת ',
    engTitle: 'Chrome Extension for Adding Vocalization to the Web',
    hebText: 'הוספת ניקוד לטקסט מכל רחבי המרשתת - בלחיצת עכבר.',
    engText: 'Add vocalization to any webpage in your browser with a click of a button',
    hebHref: 'https://chrome.google.com/webstore/detail/dicta-nakdan-add-vocalize/pjjhddgjjmhbnlfjkobjmemihhbaaipd',
    hebButtonText: 'הורידו',
    engButtonText: 'Download'

  },
  {
    logo: require('../assets/logos/nakdan.png'),
    hebTitle: 'תוסף לכרום לשיתוף טקסט מנוקד',
    engTitle: 'Chrome Extension for Sharing Vocalized Text',
    hebText: 'שיתוף טקסט מנוקד בטוויטר, בפייסבוק, או בכל מקום אחר ברחבי המרשתת.',
    engText: 'Share vocalized text on Twitter, Facebook, or any other online platform',
    hebHref: 'https://chrome.google.com/webstore/detail/dicta-nakdan-share-vocali/mihlembodjlpghjcpedlefbambhmncpf',
    hebButtonText: 'הורידו',
    engButtonText: 'Download'
  },
  {
    logo: require('../assets/logos/pasuk-finder.png'),
    hebTitle: 'איתור מקבילות',
    engTitle: 'Parallel Finder',
    hebText: 'איתור מקביליות בטקסטים המקבילים לטקסט המקור.',
    engText: 'Find parallels in Hebrew texts.',
    hebHref: 'https://parallels.dicta.org.il/',
    hebButtonText: 'התחילו לחפש',
    engButtonText: 'Search Parallels'
  },
  {
    logo: require('../assets/logos/Logo_Classify_White_Circle.png'),
    hebTitle: 'סיווג סגנוני של טקסט חופשי',
    engTitle: 'Stylistic Text Analysis',
    hebText: 'סיווג טקסטים מתוך מאגר אישי כך שיתקבלו המאפיינים הסגנוניים וההבדלים שיש בין הטקסטים.',
    engText: 'View stylistic characteristics and variations of multiple uploaded texts.',
    hebHref: 'https://generalclassify.dicta.org.il/',
    hebButtonText: 'התחילו להשוות',
    engButtonText: 'Compare Texts'
  },
  {
    logo: require('../assets/logos/morph-analysis.png'),
    hebTitle: 'ניתוח מורפולוגי',
    engTitle: 'Morphology Analysis',
    hebText: 'הצגת נתונים מורפולוגים של מילות הטקסט',
    engText: 'Morphological analysis of Hebrew text',
    hebHref: 'https://morph-analysis.dicta.org.il/',
    engHref: 'https://morph-analysis.dicta.org.il?lang=en',
    hebButtonText: 'התחילו לנתח',
    engButtonText: 'Analyze'
  },
  /* {
    logo: require('../assets/logos/Logo_RashiFont_White.png'),
    hebTitle: 'פונט בסגנון רש״י מבית דיקטה',
    engTitle: 'Rashi style font created by Dicta',
    hebText: 'פונט בסנגון כתב רש״י, נוח לקריאה וללימוד',
    engText: 'Rashi style font that is easy to use for learning and reading.',
    hebHref: '/Dicta_Rashi_font.zip',
    hebButtonText: 'הורידו את הפונט',
    engButtonText: 'Download Font'
  }, */
  {
    logo: require('../assets/logos/nakdan.png'),
    hebTitle: 'רובוט ניקוד (טלגרם)',
    engTitle: '(Telegram) Nakdan Bot',
    hebText: 'ניקוד אוטומטי של טקסט בטלגרם, על ידי שליחת הודעה שכוללת את הטקסט שמיועד לניקוד.',
    engText: 'Automatically add nikud to text being sent in telegram.',
    hebHref: 'https://t.me/dicta_nakdan_bot',
    demoLink: 'https://www.youtube.com/watch?v=u1W3mYabHRs&list=PLEcDk-aPtpbbGvpbt32mq7SJTIVorOd2X&index=1',
    demoId: 'u1W3mYabHRs',
    hebButtonText: 'התחילו לנקד',
    engButtonText: 'Add Nikud'
  },
  {
    logo: require('../assets/logos/top_phrases.png'),
    engTitle: 'Top Phrases',
    engText: 'Top phrases grouped by Bible and Talmud Results',
    hebTitle: 'ביטויים נפוצים',
    hebText: 'קיבוץ תוצאות החיפוש של התנ״ך ושל התלמוד, לקבוצות של ביטויים נפוצים.',
    hebHref: 'https://top-phrases.dicta.org.il/',
    demoLink: 'https://www.youtube.com/watch?v=2U6U4iZhR0I&list=PLEcDk-aPtpbbGvpbt32mq7SJTIVorOd2X&index=5',
    demoId: '2U6U4iZhR0I',
    hebButtonText: 'התחילו לחפש',
    engButtonText: 'Search Phrases'
  },

  {
    logo: require('../assets/logos/nikud-search.png'),
    engTitle: 'Nikud Search',
    engText: 'Find words in Bible and Talmud with identical patterns for a specific word.',
    hebTitle: 'חיפוש תבנית ניקוד',
    hebText: 'איתור מילה (בתנך או בתלמוד) שתבנית הניקוד שלה זהה לתבנית הניקוד של המילה שהוזנה בשורת החיפוש.',
    hebHref: 'https://nikudsearch.dicta.org.il/',
    hebButtonText: 'התחילו לחפש',
    engButtonText: 'Search Nikud'
  },
  {
    logo: require('../assets/logos/remove_nikud.png'),
    hebTitle: 'הסרת ניקוד',
    engTitle: 'Remove Nikud',
    hebText: 'הסרת ניקוד וטעמי המקרא מכל סוגי הטקסטים המנוקדים, עם הוספת אימות קריאה לפי כללי הכתיב של האקדמיה.',
    engText: 'Remove vocalization and add Vuvs and Yods, producing a normalized modern Hebrew text.',
    hebHref: 'http://removenikud.dicta.org.il/',
    hebButtonText: 'הסירו ניקוד',
    engButtonText: 'Remove Nikud'
  }

]
