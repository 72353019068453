export const team = [
  {
    img: require("../assets/team/moshe.png"),
    engName: "Moshe Koppel",
    hebName: "משה קופל",
  },
  {
    img: require("../assets/team/avi.png"),
    engName: "Avi Shmidman",
    hebName: "אבי שמידמן",
  },
  {
    img: require("../assets/team/josh.png"),
    engName: "Joshua Guedalia",
    hebName: "יהושע גדליה",
  },
  {
    img: require("../assets/team/shaltiel.png"),
    engName: "Shaltiel Shmidman",
    hebName: "שאלתיאל שמידמן",
  },
  {
    img: require("../assets/team/aryeh.png"),
    engName: "Aryeh Sanders",
    hebName: "אריה סנדרס",
  },
  {
    img: require("../assets/team/devora.png"),
    engName: "Dvora Bloch",
    hebName: "דבורה בלוך",
  },
  {
    img: require("../assets/team/meir.png"),
    engName: "Meir Rubin",
    hebName: "מאיר רובין",
  },
  {
    img: require("../assets/team/dovid.png"),
    engName: "Dovid Lipman",
    hebName: "דוד ליפמן",
  },
  {
    img: require("../assets/team/elisheva.png"),
    engName: "Elisheva Brauner",
    hebName: "אלישבע בראונר",
  },
  {
    img: require("../assets/team/rachel.png"),
    engName: "Rachel Tal",
    hebName: "רחל טל",
  },
  {
    img: require("../assets/team/yehuda.png"),
    engName: "Yehuda Broderick",
    hebName: "יהודה ברודריק",
  },
  {
    img: require("../assets/team/yaakov.png"),
    engName: "Yaaqov Loyfer",
    hebName: "יעקב לויפר",
  },
  {
    img: require("../assets/team/rivka.png"),
    engName: "Rivka Sharfman",
    hebName: "רבקה שרפמן",
  },
  {
    img: require("../assets/team/Yonah.png"),
    engName: "Yonah Taurog",
    hebName: "יונה טאוראג",
  },
  {
    img: require("../assets/team/ShlomoBluth.png"),
    engName: "Shlomo Bluth",
    hebName: "שלמה בלוט",
  },
  {
    img: require("../assets/team/CheynShmuelShmidman.png"),
    engName: "Cheyn Shmuel Shmidman",
    hebName: "חן שמואל שמידמן",
  },
  {
    img: require("../assets/team/EliHandel.png"),
    engName: "Eli Handel",
    hebName: "אלי הנדל",
  },
  {
    img: require("../assets/team/Ometz.png"),
    engName: "Ometz Shmidman",
    hebName: "אומץ שמידמן",
  },
  {
    img: require("../assets/team/Michael_Tsaban.png"),
    engName: "Michael Tsaban",
    hebName: "מיכאל צבאן",
  },
  {
    img: require("../assets/team/Hillel_Gershuni.png"),
    engName: "Hillel Gershuni",
    hebName: "הלל גרשוני",
  },
  {
    img: require("../assets/team/David_Fuchs.png"),
    engName: "David Fuchs",
    hebName: "דוד פוקס",
  },
  {
    img: require("../assets/team/Elisha_Rosensweig.png"),
    engName: "Elisha Rosensweig",
    hebName: "אלישע רוזנצוויג",
  },
  {
    img: require("../assets/team/CheyYannaiPicture.jpg"),
    engName: "Chen Yanai",
    hebName: "חן ינאי",
  },
]

export const partners = [
  {
    img: require("../assets/partners/tal.png"),
    engName: "Tal Sofer",
    hebName: "טל סופר",
  },
  {
    img: require("../assets/partners/img-empty.png"),
    engName: "Meni Adler",
    hebName: "מני אדלר",
  },
  {
    img: require("../assets/partners/erel.png"),
    engName: "Erel Segal-Halevi",
    hebName: "אראל סגל-הלוי",
  },
  {
    img: require("../assets/partners/josh-berman.png"),
    engName: "Joshua Berman",
    hebName: "יהושע ברמן",
  },
  {
    img: require("../assets/partners/img-empty.png"),
    engName: "Eden Erez Shalom",
    hebName: "עדן ארז שלום",
  },
  {
    img: require("../assets/partners/moshe.png"),
    engName: "Moshe Rayman",
    hebName: "משה ריימן",
  },
  {
    img: require("../assets/partners/oran.png"),
    engName: "Oran Brill",
    hebName: "אורן בריל",
  },
  {
    img: require("../assets/team/yael.png"),
    engName: "Yael Netzer",
    hebName: "יעל נצר",
  },
  {
    img: require("../assets/team/elazar.png"),
    engName: "Elazar Sprecher",
    hebName: "אלעזר שפרכר",
  },
  {
    img: require("../assets/team/yoav.png"),
    engName: "Yoav Goldberg",
    hebName: "יואב גולדברג",
  },
]
