<template>
  <div class="developer-information bg-background pb-4 flex-grow-1">
    <div
      class="mb-4 description d-flex justify-content-center text-center text-white align-items-center"
    >
      <h1>{{ hebrew ? "מפתחים" : "Developers" }}</h1>
    </div>
    <b-container>
      <span v-for="(item, index) in developmentItems" :key="index">
        <dicta-publication :publicationItem="item"></dicta-publication>
      </span>
    </b-container>
  </div>
</template>
<script>
import DictaPublication from "@/components/dictaPublication"
export default {
  name: "Developers",
  components: {
    DictaPublication,
  },
  data() {
    return {
      developmentItems: [
        {
          name: "Dicta LM",
          nameHE: "Dicta LM",
          date: "16/04/2024",
          link: "/dicta-lm",
          text: "Dicta-LM 2.0 - A large, open, and powerful generative language model in Hebrew!",
          textHE: "Dicta-LM 2.0 - מודל שפה גנרטיבי גדול בעברית, פתוח, וחזק!",
          authors: "Dicta Team",
          authorsHE: "צוות דיקטה",
        },
        {
          name: "Dicta BERT",
          nameHE: "Dicta BERT",
          date: "29/12/2023",
          link: "/dicta-bert",
          text: "DictaBERT: A State-of-the-Art BERT Suite for Modern Hebrew",
          textHE: "DictaBERT: חבילת BERT חדישה לעברית מודרנית",
          authors: "Dicta Team",
          authorsHE: "צוות דיקטה",
        },
      ],
    }
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
  },
}
</script>
