<template>
  <b-modal
    id="video-modal"
    v-model="show" 
    ok-only 
    centered 
    size="lg" 
    hide-footer
    hide-header
    header-class="d-none d-sm-block"    
    >
   <div>
  <b-embed
    type="iframe"
    aspect="16by9"
    :src="'https://www.youtube.com/embed/' +videoId+ '?rel=0&autoplay=1'"
    allow='autoplay'
    allowfullscreen
  ></b-embed>
</div>
  </b-modal>
</template>
<script>
export default {
  name: "VideoDialog",
  props: ["value", "videoId"],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>
