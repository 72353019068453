export const tools = [
  {
    href: "https://nakdanlive.dicta.org.il/",
    logo: require("../assets/logos/nakdan.png"),
    engTitle: "Quick Nakdan",
    engSubtitle: "Automatically add nikud (vocalization) to text as you type.",
    engLinkTitle: "Add Nikud",
    hebTitle: "נקדן מהיר",
    hebSubtitle: "ניקוד אוטומטי של טקסטים בעברית מודרנית, תוך כדי הקלדה.",
    hebLinkTitle: "התחילו לנקד",
    href2: "https://nakdanpro.dicta.org.il/",
    engTitle2: "Nakdan Pro",
    engSubtitle2:
      "Automatic Nakdan with an interface that is adapted for professionals. The Nakdan offers a variety of solutions designed to significantly shorten the time required in adding vocalization",
    engLinkTitle2: "Add Nikud",
    hebTitle2: "נקדן מקצועי",
    hebSubtitle2:
      "נקדן אוטומטי עם ממשק מותאם לאנשי מקצוע. הנקדן מציע מגוון פתרונות שנועדו לקצר משמעותית את זמן ניקוד הטקסטים.",
    hebLinkTitle2: "התחילו לנקד",
  },
  {
    engHref: "https://search.dicta.org.il/",
    hebHref: "https://search.dicta.org.il/he",
    logo: require("../assets/logos/search.png"),
    engTitle: "Search the Bible",
    engSubtitle:
      "Search the Bible intuitively, with no need to worry about alternate spellings, prefixes and suffixes or sorting out multiple meanings of a word. Dicta’s search engine understands what you’re looking for.",
    engLinkTitle: "Start search",
    hebTitle: 'חיפוש בתנ"ך',
    hebSubtitle:
      'חיפוש מילים וביטויים בתנ"ך באופן אינטואיטיבי, בלי צורך להתעסק בהבדלי איות והטיה.',
    hebLinkTitle: "התחילו לחפש",
  },
  {
    href: "https://translate.dicta.org.il/",
    logo: require("../assets/logos/translate.png"),
    date: "09/06/2024",
    hebTitle: "מתרגם",
    hebSubtitle:
      "תרגום אוטומטי דו-כיווני של עברית-אנגלית ואנגלית-עברית, שמבוסס על מודל שפה גנרטיבי בעברית.",
    hebLinkTitle: "התחילו לתרגם",
    engTitle: "Translator",
    engSubtitle:
      "Two-way automatic translation of Hebrew-English and English-Hebrew, based on a Dicta's generative language model.",
    engLinkTitle: "Start translating",
  },
  {
    href: "https://wordplay.dicta.org.il/",
    logo: require("../assets/logos/charuzit.png"),
    engTitle: "Charuzit",
    engSubtitle:
      "Find rhymes, assonance, and alliteration for any given Hebrew word. Search results can be filtered by entering semantic  words and configuring various grammatical settings.",
    engLinkTitle: "Start rhyming",
    hebTitle: "חרוזית",
    hebSubtitle:
      "איתור חרוזים, מצלול ואליטרציה על בסיס מילת חיפוש. אפשר לסנן את תוצאות החיפוש באמצעות הזנת מילים משדה סמנטי רצוי ומאפיינים דקדוקיים נוספים.",
    hebLinkTitle: "התחילו לחרוז",
  },
  {
    href: "https://library.dicta.org.il/",
    logo: require("../assets/logos/library.png"),
    engTitle: "Dicta Library",
    xengSubtitle:
      "Dicta's library includes a database of live texts that have undergone an automatic scanning process. The texts are displayed clearly and you can display automatic nikud, punctuation, and more.",
    engSubtitle: `Dicta's library enhances access to rabbinic literature by automatically adding nikud, punctuation, expanding acronyms, and providing references to sources mentioned in the text. Users can choose from a variety of books within the library or, soon, upload their own rabbinic texts and view them in an accessible format in real time.
      `,
    engLinkTitle: "Browse",
    hebTitle: "הספרייה של דיקטה",
    hebSubtitle: `הספרייה של דיקטה מנגישה את הספרות הרבנית באמצעות הוספה אוטומטית של ניקוד, פיסוק, פיענוח ראשי תיבות וכן הפניות למקורות שמאוזכרים בטקסט.
      ניתן לבחור ספר מהספרייה, או (בקרוב) להעלות טקסט רבני כלשהו אף אם אינו קיים בספרייה, ולראות אותו בצורה מונגשת בזמן אמת.`,
    hebLinkTitle: "התחילו לחפש",
  },
  /*  {
    logo: require("../assets/logos/LogoBinah_White.png"),
    engTitle: "Dicta Maivin",
    engSubtitle: `"Maivin" makes rabbinic literature accessible by automatically vocalizing and punctuating it, opening abbreviations, and identifying source texts. 
    <br>
    You can choose a book from the Dicta library, or (coming soon) upload any rabbinic text even if it’s not in the Dicta library, and see it in processed form in real time. 
    `,
    hebTitle: "דיקטה ״מֵייבִין״",
    hebSubtitle: `״מֵייבִין״ מנגיש את הספרות הרבנית באמצעות הוספה אוטומטית של ניקוד, פיסוק, פיענוח ראשי תיבות וכן הפניות למקורות שמאוזכרים בטקסט. 
    <br>
ניתן לבחור ספר מהספרייה של דיקטה, או (בקרוב) להעלות טקסט רבני כלשהו אף אם אינו קיים בספרייה של דיקטה, ולראות אותו בצורה מונגשת בזמן אמת.
    `,
    engLinkTitle: "Dicta library",
    engLinkTitle2: "Contact us",
    hebLinkTitle2: "צרו קשר",
    href: "https://library.dicta.org.il/",
    appHref2: "#",
    hebLinkTitle: "לספרייה של דיקטה",
  },
    {
    logo: require('../assets/logos/LogoBinah_White.png'),
    engTitle: 'Dicta Mayven',
    engSubtitle: '"Mayven" makes rabbinic literature accessible by automatically adding to it nikud, punctuation, and more.',
    mobileEngSubtitle: '"Mayven\'s" technology makes rabbinic literature accessible by automatically adding to it nikud, punctuation, and expanding abbreviations, in addition to referencing and linking to sources cited in the text.',
    engLinkTitle: 'Contact us',
    hebTitle: 'דיקטה מבין',
    hebSubtitle: '״מבין״ מנגיש את הספרות הרבנית ע"י הוספה אוטומטית של ניקוד, פיסוק, פיענוח ר"ת, וכן הפניות וקישורים למקורות המובאים בטקסט.',
    mobileHebSubtitle: 'הטכנולוגיה של ״מבין״ מנגישה את הספרות הרבנית על ידי הוספה אוטומטית של ניקוד, פיסוק, פיענוח ראשי תיבות, וכן הפניות וקישורים למקורות המובאים בטקסט.',
    hebLinkTitle: 'צרו קשר',
    href2: 'https://library.dicta.org.il/',
    engTitle2: 'Dicta Library',
    engSubtitle2: 'Database of live texts that have undergone automatic scanning.',
    engMobileSubtitle2: 'Dicta\'s library includes a database of live texts that have undergone an automatic scanning process.The texts are displayed clearly and you can display automatic nikud, punctuation, and more.',
    engLinkTitle2: 'Browse',
    hebTitle2: 'הספריה של דיקטה',
    hebSubtitle2: 'מאגר טקסטים חיים שעברו תהליך סריקה אוטומטית.',
    hebMobileSubtitle2: 'הספריה של דיקטה כוללת מאגר טקסטים חיים שעברו תהליך סריקה אוטומטית. הטקסטים מוצגים בצורה בהירה וניתן להוסיף להם ניקוד אוטומטי, פיסוק אוטומטי, ועוד.',
    hebLinkTitle2: 'התחילו לחפש'
  },
   */
  {
    href: "https://citation.dicta.org.il/",
    logo: require("../assets/logos/pasuk-finder.png"),
    engTitle: "Citation Finder",
    engSubtitle:
      "Identify exact or approximate quotations of biblical and talmudic sources in a given text.",
    engLinkTitle: "Find citations",
    hebTitle: "איתור אזכורים",
    hebSubtitle:
      "איתור אזכורים (מדויקים ולא מדויקים) תנ״כיים ותלמודיים בטקסטים.",
    hebLinkTitle: "התחילו לחפש",
  },
  {
    href: "http://abbreviation.dicta.org.il/",
    logo: require("../assets/logos/abbreviations.png"),
    engTitle: "Rabbinic Abbreviation Expander",
    engSubtitle:
      "Expand abbreviations in Rabbinic texts. An entered text will be displayed including expanded abbreviations. The automated expansions are editable by the user.",
    engLinkTitle: "Start expanding",
    hebTitle: "פיענוח ראשי תיבות רבניים",
    hebSubtitle:
      "פיענוח ראשי תיבות בטקסטים רבניים. לאחר הזנת הטקסט יוצג הטקסט המקורי שיכלול את פענוח ראשי התיבות. ניתן גם לערוך ולבחור בין אפשרויות פענוח חלופות.",
    hebLinkTitle: "התחילו לפענח",
  },
  {
    engHref: "https://talmudsearch.dicta.org.il/",
    hebHref: "https://talmudsearch.dicta.org.il/he",
    logo: require("../assets/logos/talmud.png"),
    engTitle: "Talmud Search",
    engSubtitle:
      "Search the Talmud and Mishnah for words and phrases intuitively, with no need to worry about alternate spellings and multiple meanings. Dicta’s search engine understands what you’re looking for.",
    engLinkTitle: "Start search",
    hebTitle: "חיפוש בתלמוד",
    hebSubtitle:
      "חיפוש מילים וביטויים בתלמוד ובמשנה באופן אינטואיטיבי, מבלי להתעסק בהבדלי איות והטיה.",
    hebLinkTitle: "התחילו לחפש",
  },
  {
    href: "https://synoptic.dicta.org.il/",
    logo: require("../assets/logos/synoptic.png"),
    engTitle: "Synopsis Builder",
    engSubtitle:
      "Align two or more versions of the same (arbitrarily long) text, highlighting differences between versions and matching parallel words, including variant spellings and synonyms.",
    engLinkTitle: "Build synopsis",
    hebTitle: "השוואת גרסאות (סינופסיס)",
    hebSubtitle:
      "השוואה בין שתי גרסאות ומעלה של טקסט. הכלי מסמן את ההבדלים בין הגרסאות השונות ומאתר את המילים המקבילות (כולל שינויים בכתיב ומילים נרדפות).",
    hebLinkTitle: "התחילו להשוות",
  },
  {
    href: "https://tiberias.dicta.org.il",
    logo: require("../assets/logos/classify.png"),
    engTitle: "Tiberias: Bible Classification",
    engSubtitle: "Categorize text using stylistic markers",
    engLinkTitle: "Classify",
    hebTitle: "סיווג סגנוני של טקסטים מקראיים",
    hebSubtitle:
      "סיווג טקסטים קטגוריות טקסטואליות על בסיס מאפיינים סגנוניים ושיוכם לקטגוריה הקרוב ביותר מבחינה סגנונית. לדוגמא: ניתן לסמן דוגמאות של קטגוריית עברית מקראית קדומה, למשל יהושע שופטים, ודוגמאות של קטגוריית עברית מקראית מאוחרת, למשל אסתר ועזרא. הכלי יאפיין כל קטגוריה מבחינה סגנונית ואז יכריע לאיזו קטגוריה שייך טקסט שלישי שנבחר, כדוגמת יואל.",
    hebLinkTitle: "התחילו להשוות",
  },
  {
    engHref: "https://segment.dicta.org.il/?lang=en",
    hebHref: "https://segment.dicta.org.il/",
    logo: require("../assets/logos/segmentation.png"),
    engTitle: "Stylistic Segmentation",
    engSubtitle:
      "Partition any selected text into distinct stylistic components. For example, a multi-authored text can be automatically decomposed and displayed so that distinct authorial threads are shown in different colors.",
    engLinkTitle: "Segment text",
    hebTitle: "פילוח סגנוני",
    hebSubtitle: "חלוקת טקסט לפי מאפיינים סגנוניים",
    hebLinkTitle: "התחילו להשוות",
  },
  {
    logo: require("../assets/logos/illuminate.png"),
    engTitle: "Illuminate",
    engSubtitle:
      "A new application that enables the automatic transition from printed text to digital text, by identifying the exact source of a text as it appears in an image.",
    engLinkTitle2: "App Store",
    engLinkTitle: "Google Play",
    hebLinkTitle2: "App Store",
    hebLinkTitle: "Google Play",
    href: "https://play.google.com/store/apps/details?id=org.sefaria.illuminate&hl=en_US&gl=US", //
    appHref2: "https://apps.apple.com/app/dicta-illuminate/id1633250535",
    hebTitle: "זיהוי טקסט",
    hebSubtitle:
      "אפליקציה המאפשרת מעבר אוטומטי מטקסט מודפס לטקסט דיגיטלי, באמצעות צילום תמונה מהטקסט ואיתור המקור המדוייק.",
  },

  {
    href: "https://talmud.dicta.org.il/",
    logo: require("../assets/logos/shas-library.png"),
    engTitle: "Vocalized Talmud",
    engSubtitle:
      "The Vocalized Talmud here was made possible thanks to the Dicta Rabbinic Nakdan. All tractates can be viewed with normative vocalized orthography or with the orthography of the standard printings.",
    engLinkTitle: "Shas library",
    hebTitle: "הש״ס המנוקד",
    hebSubtitle:
      "מסכתות מנוקדות בכתיב מלא ובכתיב חסר. ניקוד הש״ס התאפשר הודות לנקדן הרבני מבית דיקטה שמתחשב בכללי הניקוד הייחודיים לטקסטים רבניים מוקדמים ומאוחרים.",
    hebLinkTitle: "לספרייה של הש״ס",
  },
]
