<template>
  <div class="about">
    <div
      class="description d-flex justify-content-center text-center text-white align-items-center"
    >
      <h1>
        {{ hebrew ? "הכירו את הצוות של דיקטה" : "Meet the people of Dicta" }}
      </h1>
    </div>
    <div class="text-block">
      <div class="container">
        <span v-if="hebrew">
          <p>
            עמותת דיקטה מספקת לכל המתעניינים בטקסטים בעברית כלים המבוססים על
            האלגוריתמים המתקדמים ביותר של למידת מכונה ועיבוד שפה טבעית כדי לנתח
            טקסטים קלאסיים ומודרניים כאחד.
          </p>
          <p>
            בעזרת כלי דיקטה המשתמשים יכולים לנקד את הטקסט, לערוך חיפוש מורכב
            בתנ"ך ובתלמוד, להשוות בין עדי נוסח, למצוא שיבוצים בטקסט – כל אלה
            ועוד בלחיצת כפתור. הכלים עומדים גם לרשות חוקרים ומסייעים להם להכין
            תשתית למחקרים בתחום מדעי הרוח הדיגיטליים.
          </p>
          <p>
            דיקטה מציעה לציבור מאגר של הספרות הרבנית לדורותיה, שבו הטקסטים
            מונגשים באמצעות ניקוד, פיסוק, פיענוח ראשי תיבות, ציון מקורות והצגת
            מקבילות.
          </p>
          <p>
            דיקטה היא עמותה ללא מטרת רווח והיא מציעה את תוצריה לתועלת הציבור
            חינם אין כסף.
          </p>
        </span>
        <span v-else>
          <p>
            Dicta provides tools based on the most advanced algorithms of
            machine learning and natural language processing to analyze both
            classical and modern Hebrew texts.
          </p>
          <p>
            With Dicta's tools, users can vocalize texts, conduct complex Bible
            and Talmud searches, compare textual witnesses, find citations
            texts, and more with the click of a button. The tools are also
            available to researchers and help them prepare a base for research
            in the field of digital humanities.
          </p>
          <p>
            Dicta offers the public a repository of generations of rabbinic
            literature, made accessible with punctuation, abbreviation
            expansion, citations, and parallels.
          </p>
          <p>
            Dicta is a non-profit association and offers its products for the
            public benefit free of charge.
          </p>
        </span>
      </div>
    </div>
    <div class="dicta-people text-center">
      <div class="container">
        <h2>{{ hebrew ? "הצוות של דיקטה" : "DICTA TEAM" }}</h2>
        <div class="row">
          <div
            class="col-12 col-md-3 mb-5"
            v-for="(member, index) in team"
            :key="index"
          >
            <img
              class="team-img"
              :class="{ 'chen-y-img': index === team.length - 1 }"
              :src="member.img"
              :alt="member.engName"
            />
            <h3 class="mt-2">{{ hebrew ? member.hebName : member.engName }}</h3>
          </div>
        </div>

        <div class="partners">
          <h2>{{ hebrew ? "שותפים" : "PARTNERS" }}</h2>
          <div class="row">
            <div
              class="col-12 col-md-3 text-center mb-5"
              v-for="(partner, index) in partners"
              :key="index"
            >
              <img class="team-img" :src="partner.img" :alt="partner.engName" />
              <h3 class="mt-2">
                {{ hebrew ? partner.hebName : partner.engName }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { team } from "./aboutList"
import { partners } from "./aboutList"

export default {
  name: "About",
  components: {},
  data() {
    return {
      team,
      partners,
    }
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
  },
  mounted() {
    document
      .getElementById("top-actions")
      .setAttribute("style", "visibility:inherit;")
    document
      .getElementById("navigation")
      .setAttribute("style", "visibility:inherit;")
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.text-block {
  font-size: 20px;
  background: #f6f8fa;
  padding: 50px 0 15px;
  p {
    margin: 0 0 25px;
  }
}
.dicta-people {
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
  padding: 50px 0 15px;
  border-top: solid 1px #d8d8d8;
  h2 {
    font-size: 32px;
    margin: 0 0 40px 0;
  }
  .partners {
    padding-top: 65px;
    border-top: solid 1px #979797;
  }
}
.chen-y-img {
  height: 184px;
  width: 184px;
  border-radius: 50%;
  filter: grayscale(100%);
}
</style>
