<template>
  <section class="footer-section">
    <div class="footer-top" v-if="['Home'].includes($route.name)">
      <div class="container">
        <div class="tools-heading text-center">
          {{ hebrew ? "בקרוב" : "Coming soon" }}
        </div>
        <div class="blue-sep"></div>
        <div class="w-75 m-auto">
          <ul>
            <li class="text-md-center">
              <h3>
                {{ hebrew ? "סימון מילים פגומות" : "Flag corrupted words" }}
              </h3>
            </li>

            <li class="text-md-center">
              <h3>{{ hebrew ? "ביקורת מקור" : "Identify text origin" }}</h3>
            </li>

            <li class="text-md-center">
              <h3>{{ hebrew ? "זיהוי זיופים" : "Forgery detection" }}</h3>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <footer id="footer">
      <div class="container">
        <div class="footer-txt">
          {{
            hebrew
              ? "יש לכם רעיונות לשיתוף פעולה"
              : "Interested in partnering with Dicta"
          }}?
          <span
            @click="showForm = !showForm"
            id="footer-link"
            class="contant-footer"
          >
            {{ hebrew ? "צרו איתנו קשר" : "Please contact us" }}.</span
          >
        </div>

        <div class="py-2 d-flex justify-content-between">
          <div>
            <div class="logo">
              <router-link id="bottomLinkToTop" class="text-body" to="/"
                >DICTA</router-link
              >
            </div>
            <p class="txt text-body d-none d-sm-inline-block">
              {{
                hebrew
                  ? "כלים דיגיטליים לעיבוד טקסטים עבריים"
                  : "Analytical tools for Hebrew texts"
              }}
            </p>
          </div>
          <div :class="{ 'text-left': hebrew, 'text-right': !hebrew }">
            <div class="btn-group social d-flex justify-content-between mw-100">
              <router-link class="text-body bt bt-sm link" to="tos">
                {{ hebrew ? "תנאי השימוש" : "Terms of Service" }}</router-link
              >
              <a class="bt bt-sm link" @click="changeLanguage">{{
                hebrew ? "En" : "עב"
              }}</a>
              <!-- <a id="linkToFacebook" target="black" href="https://www.facebook.com/dictatools/" class="facebook"><img src="images/Facebook.png"></a> -->
              <a
                class="text-body"
                id="linkToFacebook"
                target="_blank"
                href="https://www.facebook.com/dictatools/"
              >
                <i class="fa fa-facebook" aria-hidden="true"></i>
              </a>
              <a
                class="text-body"
                id="linkToTwitter"
                target="_blank"
                href="https://twitter.com/DictaTools"
              >
                <i class="fa fa-twitter"></i>
              </a>
              <a
                class="text-body"
                id="linkToYoutube"
                target="_blank"
                href="https://www.youtube.com/channel/UC4ickfqPHtDMAKIGvMBMQyw"
              >
                <i class="fa fa-youtube"></i>
              </a>
              <a
                class="text-body"
                id="linkToHuggingFace"
                target="_blank"
                href="https://huggingface.co/dicta-il"
              >
                <img
                  style="height: 17px"
                  src="../assets/huggingface.png"
                  alt="Hugging Face"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        id="dropdown-footer"
        class="dropdown"
        v-bind:class="{ closed: !showForm }"
      >
        <Form></Form>
        <a @click="showForm = false" id="cancel-below" class="cancel"
          ><img src="../assets/ic_cancel.png"
        /></a>
      </div>
    </footer>
  </section>
</template>

<script>
import Form from "../components/form"
export default {
  name: "Footer",
  data() {
    return {
      showForm: false,
    }
  },
  components: {
    Form,
  },
  mounted() {
    // console.log(this.$route.name)
  },
  methods: {
    changeLanguage() {
      this.$emit("ChangeLanguage")
    },
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
  },
}
</script>
<style lang="scss" scoped>
.footer-section {
  background: #f6f6f6;

  .footer-top {
    padding: 30px 0 40px;
    text-align: center;
    border-top: solid 4px #007ee5;
    border-bottom: solid 1px #d8d8d8;
    background: url(../assets/bg-image.png) no-repeat 50% 50%;
    background-size: cover;
    margin: 0 0 50px;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);

    @media only screen and (max-width: 991px) {
      margin: 0 0 28px;
      padding: 25px 0 15px;
      background: url("../assets/bg-image-mobile.png") no-repeat 0 0;
    }

    ul {
      display: inline-block;

      @media only screen and (max-width: 991px) {
        display: block;
        margin-bottom: 0;
      }

      vertical-align: top;
      padding: 0 20px;

      li {
        margin: 0 0 10px;
        list-style: none;
        text-align: left;

        h3 {
          position: relative;
          padding: 0 25px;
          font-size: 20px;

          @media only screen and (max-width: 991px) {
            font-size: 16px;
          }

          &::before {
            content: "";
            width: 18px;
            height: 18px;
            position: absolute;
            left: 0;
            top: 1px;
            background: #f6f6f6 url(../assets/ic_next.png) no-repeat 50% 50%;
          }
        }
      }
    }
  }

  #footer {
    position: relative;
    height: 120px;
    padding: 0 0 10px;
    background-color: #f6f8fa;
    border-top: solid 1px #d8d8d8;

    .txt {
      font-size: 16px;
      line-height: 19px;
      margin: 0;
      padding: 0 14px;
      vertical-align: middle;
    }
  }

  .footer-txt {
    padding: 20px 0 15px;
    margin: 0 0 9px;
    border-bottom: solid 1px #c8c8c8;
    font-size: 20px;

    @media only screen and (max-width: 991px) {
      font-size: 16px;
    }

    line-height: 23px;

    span {
      cursor: pointer;
      border-bottom: 2px solid #007ee5;

      &:hover {
        color: #007ee5;
      }
    }
  }

  .logo {
    font-size: 30px;
    line-height: 30px;
    @media only screen and (max-width: 991px) {
      font-size: 22px;
      line-height: 22px;
    }
    font-family: "Arimo", sans-serif;
    display: inline-block;
    vertical-align: middle;
  }

  i {
    font-family: FontAwesome;
    font-weight: 400;
  }

  .bt.link {
    text-decoration: underline;
    cursor: pointer;
  }
}

.he .footer-section .footer-top {
  ul li {
    text-align: right;

    h3 {
      &::before {
        background: #f6f6f6 url(../assets/ic_next-he.png) no-repeat 50% 50%;
        left: inherit;
        right: 0;
      }
    }
  }
}

.social {
  width: 240px;
}

.dropdown {
  // display: none;

  @media only screen and (max-width: 1024px) {
    height: 100%;
    bottom: 0;
    overflow: hidden;
    position: fixed;
    left: 0;
    z-index: 99;
  }

  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 119px;
  background-color: #ffffff;
  box-shadow: 0 -4px 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 9999;
  border-bottom: solid 1px #c8c8c8;

  &.closed {
    height: 0;
    overflow-y: hidden;
  }
}

.cancel {
  cursor: pointer;
  position: absolute;
  top: 28px;
  left: 37px;
  z-index: 1;
  top: 15px;

  @media only screen and (max-width: 1024px) {
    left: 15px;
  }
}
</style>
<style lang="scss">
.he .cancel {
  left: inherit;
  right: 37px;

  @media only screen and (max-width: 1024px) {
    right: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .he .footer-section .footer-top {
    background: url("../assets/bg-image-mobile-he.png") no-repeat 0 0;
  }
}
</style>
