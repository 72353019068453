<template>
    <div class="mobile-menu bg-primary">
      <span :dir="computedHebrew ? 'ltr' : 'rtl'">
        <a
          id="mobile-toolbar-button"
          @click="showDictaToolsPopup"
          
          class="text-white"
          >
          <i class="fas fa-bars"></i>
        </a>
        <a
          id="mobile-about-button"
          class="text-white mx-3"
          @click="openHowItWorks"
          >
          <i class="fa fa-info-circle"></i>
        </a>
      </span>
      <dicta-tools-popup v-model="toggleDictaTools" :hebrew="hebrew" :siteUrl="siteUrl" :englishSupported="englishSupported" :custom-links="customLinks" @menu-closed="$emit('menu-open', false)"/>
    </div>
</template>
<script>

import DictaToolsPopup from './dictaToolsPopup'

export default {
  name: 'mobile-menu',
  components: { DictaToolsPopup },
  props: ['hebrew', 'englishSupported', 'customLinks', 'siteUrl'],
  data () {
    return {
      toggleDictaTools: false
    }
  },
  methods: {
    openHowItWorks () {
      this.$emit('show-about-modal')
    },
    showDictaToolsPopup (){
      this.toggleDictaTools = !this.toggleDictaTools
      this.$emit('menu-open', this.toggleDictaTools)
    }
  },
  computed: {
    computedHebrew () {
      return this.$settings.hebrew
    }
  }
}
</script>
