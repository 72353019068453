<template>
    <div class="dicta-tools">
      <div class="description d-flex justify-content-center text-center text-white align-items-center">
        <h1>{{ hebrew ? 'הכלים שלנו ' : 'Dicta\'s Tools' }}</h1>
      </div>
      <Tools></Tools>
    </div>
</template>
<script>
import Tools from "@/components/tools"
export default {
    name: 'DictaTools',
    components: {
        Tools,
    },
    computed: {
      hebrew() {
        return this.$settings.hebrew
      }
    } 
}  
</script>