<template>
  <div class="dicta-tools-section">
    <section class="dicta-tools container pt-5">
      <!-- <div class="tools-heading text-center">{{ hebrew ? 'הכלים שלנו' : 'Our Tools' }}</div>
            <div class="blue-sep"></div> -->
      <div class="row">
        <div
          class="col-xl-4 col-lg-6"
          v-for="(tool, index) in tools"
          :key="index"
          :id="
            tool.engTitle.split(' ').join('_').replace(':', '').toLowerCase()
          "
        >
          <div
            class="product-wrap"
            :class="{ 'nakdan-auto': tool.hasOwnProperty('href2') }"
          >
            <a
              :href="
                tool.hasOwnProperty('href')
                  ? tool.href
                  : hebrew
                  ? tool.hebHref
                  : tool.engHref
              "
              class="tool-link bg-primary d-block product-logo position-relative"
            >
              <img
                class="logo"
                :alt="
                  hebrew ? 'לוגו ' + tool.hebTitle : tool.engTitle + ' logo'
                "
                :src="tool.logo"
              />
            </a>
            <a
              class="tool-title"
              :href="
                tool.hasOwnProperty('href')
                  ? tool.href
                  : hebrew
                  ? tool.hebHref
                  : tool.engHref
              "
            >
              <h2 class="text-body">
                {{ hebrew ? tool.hebTitle : tool.engTitle }}
              </h2>
            </a>
            <div class="product-description position-relative">
              <span
                v-html="hebrew ? tool.hebSubtitle : tool.engSubtitle"
              ></span>

              <a
                v-if="tool.href || tool.hebHref"
                :href="
                  tool.hasOwnProperty('href')
                    ? tool.href
                    : hebrew
                    ? tool.hebHref
                    : tool.engHref
                "
                class="product-btn"
              >
                {{ hebrew ? tool.hebLinkTitle : tool.engLinkTitle }}
                <i
                  v-if="hebrew"
                  class="fa fa-arrow-left"
                  aria-hidden="true"
                ></i>
                <i v-else class="fa fa-arrow-right" aria-hidden="true"></i>
              </a>
              <a
                v-if="tool.hasOwnProperty('appHref2') && tool.appHref2 !== '#'"
                :href="tool.appHref2"
                class="product-btn second-btn"
              >
                {{ hebrew ? tool.hebLinkTitle2 : tool.engLinkTitle2 }}
                <i
                  v-if="hebrew"
                  class="fa fa-arrow-left"
                  aria-hidden="true"
                ></i>
                <i v-else class="fa fa-arrow-right" aria-hidden="true"></i>
              </a>
              <a
                v-if="tool.hasOwnProperty('appHref2') && tool.appHref2 === '#'"
                class="product-btn second-btn text-primary bg-white text-underline px-0"
                @click="openForm"
              >
                {{ hebrew ? tool.hebLinkTitle2 : tool.engLinkTitle2 }}
              </a>
              <b-btn
                class="product-demo px-0 bg-transparent shadow-none"
                v-if="tool.engTitle === 'Nakdan'"
                id="nakdanDemoLink"
                variant="link"
                @click="
                  showVideo = true
                  vidID = 'FkXcFUH0Ljg'
                "
              >
                <i
                  class="fa fa-youtube-play"
                  :class="{ 'ml-1': hebrew, 'mr-1': !hebrew }"
                  aria-hidden="true"
                ></i>
                <span>{{ hebrew ? "הדגמה" : "Demo" }}</span>
              </b-btn>
            </div>
            <div class="nakdan-pro" v-if="tool.hasOwnProperty('href2')">
              <a
                class="tool-title"
                :href="
                  tool.hasOwnProperty('href2')
                    ? tool.href2
                    : hebrew
                    ? tool.hebHref2
                    : tool.engHref2
                "
              >
                <h2 class="text-body">
                  {{ hebrew ? tool.hebTitle2 : tool.engTitle2 }}
                </h2>
              </a>
              <div class="product-description position-relative">
                {{
                  hebrew
                    ? smallScreen && tool.hebMobileSubtitle2
                      ? tool.hebMobileSubtitle2
                      : tool.hebSubtitle2
                    : smallScreen && tool.engMobileSubtitle2
                    ? tool.engMobileSubtitle2
                    : tool.engSubtitle2
                }}
                <a
                  :href="
                    tool.hasOwnProperty('href2')
                      ? tool.href2
                      : hebrew
                      ? tool.hebHref2
                      : tool.engHref2
                  "
                  class="product-btn"
                >
                  {{ hebrew ? tool.hebLinkTitle2 : tool.engLinkTitle2 }}
                  <i
                    v-if="hebrew"
                    class="fa fa-arrow-left"
                    aria-hidden="true"
                  ></i>
                  <i v-else class="fa fa-arrow-right" aria-hidden="true"></i>
                </a>

                <b-btn
                  v-if="tool.engTitle === 'Quick Nakdan'"
                  class="product-demo px-0 bg-transparent shadow-none"
                  variant="link"
                  @click="
                    showVideo = true
                    vidID = 'ux2MZ6VWpyo'
                  "
                >
                  <i
                    class="fa fa-youtube-play"
                    :class="{ 'ml-1': hebrew, 'mr-1': !hebrew }"
                    aria-hidden="true"
                  ></i>
                  <span>{{ hebrew ? "הדגמה" : "Demo" }}</span>
                </b-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="dicta-tools beta-tools bg-background pt-3 pb-5">
      <div class="container">
        <div class="tools-heading text-center">
          {{ hebrew ? "כלים במעבדה (בטא)" : "Beta Tools" }}
        </div>
        <div class="blue-sep"></div>
        <div class="row">
          <div
            class="col-md-6 py-md-4 mt-3 mt-sm-0"
            :class="
              (hebrew && index % 2 == 0) || (!hebrew && index % 2 != 0)
                ? 'pl-md-4'
                : 'pr-md-4'
            "
            v-for="(tool, index) in betaTools"
            :key="index"
          >
            <div class="mb-2">
              <div class="d-inline-block" style="vertical-align: top">
                <a
                  :href="
                    hebrew
                      ? tool.hebHref
                      : tool.engHref
                      ? tool.engHref
                      : tool.hebHref
                  "
                >
                  <div
                    class="beta-logo-wrap d-flex bg-primary"
                    :class="hebrew ? 'ml-3' : 'mr-3'"
                  >
                    <img
                      :id="tool.engTitle.replaceAll(' ', '-')"
                      class="logo d-block m-auto"
                      :alt="
                        hebrew
                          ? 'לוגו ' + tool.hebTitle
                          : tool.engTitle + ' logo'
                      "
                      :src="tool.logo"
                    />
                  </div>
                </a>
              </div>
              <div class="d-inline-block w-75" style="vertical-align: top">
                <h2 class="mb-1">
                  <a
                    class="text-body"
                    :href="
                      hebrew
                        ? tool.hebHref
                        : tool.engHref
                        ? tool.engHref
                        : tool.hebHref
                    "
                  >
                    {{ hebrew ? tool.hebTitle : tool.engTitle }}
                  </a>
                </h2>
                {{ hebrew ? tool.hebText : tool.engText }}
              </div>
            </div>
            <div v-if="hebrew" class="text-left" :dir="hebrew ? 'rtl' : 'ltr'">
              <b-btn
                class="ml-2 demo-link px-0 bg-transparent shadow-none"
                variant="link"
                v-if="tool.demoId"
                @click="
                  showVideo = true
                  vidID = tool.demoId
                "
              >
                <i
                  class="ml-1 position-relative fa fa-youtube-play"
                  aria-hidden="true"
                ></i>
                <span>הדגמה</span>
              </b-btn>
              <a
                class="text-body border border-dark py-1 px-2 rounded"
                :href="tool.hebHref"
              >
                {{ tool.hebButtonText ? tool.hebButtonText : "התחל" }}
                <i aria-hidden="true" class="fa fa-arrow-left"></i>
              </a>
            </div>
            <div v-else class="text-right">
              <b-btn
                class="mr-2 demo-link px-0 bg-transparent shadow-none"
                variant="link"
                v-if="tool.demoId"
                @click="
                  showVideo = true
                  vidID = tool.demoId
                "
              >
                <i
                  class="mr-1 position-relative fa fa-youtube-play"
                  aria-hidden="true"
                ></i>
                <span>Demo</span>
              </b-btn>
              <a
                class="text-body border border-dark py-1 px-2 rounded"
                :href="tool.engHref ? tool.engHref : tool.hebHref"
              >
                {{ tool.engButtonText ? tool.engButtonText : "Start" }}
                <i aria-hidden="true" class="fa fa-arrow-right"></i>
              </a>
            </div>
            <hr class="mt-3 my-3" />
          </div>
        </div>
      </div>
    </section>
    <video-modal v-model="showVideo" :videoId="vidID"></video-modal>
  </div>
</template>
<script>
import { tools } from "./toolList"
import { betaTools } from "./betaToolList"
import VideoModal from "@/components/VideoModal"

export default {
  name: "Tools",
  components: {
    VideoModal,
  },
  data() {
    return {
      tools,
      betaTools,
      vidID: null,
      showVideo: false,
    }
  },
  methods: {
    openForm() {
      if (window.innerWidth > 767) window.scrollTo(0, 0)
      document.getElementById("contactUsButton").click()
    },
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    smallScreen() {
      return window.innerWidth < 768
    },
  },
}
</script>
<style scoped lang="scss">
.dicta-vision {
  font-size: 18px;
  padding: 50px 15px 40px;
  background-color: #bfe1fe;
  margin-top: 70px;

  .vision-img-wrap {
    height: 105px;
    width: 130px;
    padding: 13px 12px;
    border-radius: 6px;
    top: 0;
    margin-top: -40px;
    left: 50%;
    margin-left: -65px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.product-wrap {
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  padding: 34px 24px 0 24px;
  margin-bottom: 43px;
  position: relative;

  @media only screen and (min-width: 992px) {
    height: 550px;
    overflow: hidden;
  }

  .product-logo {
    height: 153px;
    margin-bottom: 20px;
    border-radius: 6px;

    img {
      height: 83px !important;
      width: auto !important;
      margin: auto;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .tool-title:hover {
    text-decoration: none;
  }

  h2 {
    font-size: 21px;
  }

  .product-description {
    height: 310px;
    margin-top: 6px;
    line-height: 20px;

    @media only screen and (max-width: 991px) {
      height: auto !important;
      padding-bottom: 70px;
    }
  }

  .product-btn {
    background-color: #ffa900;
    color: #000;
    position: absolute;
    bottom: 15px;
    right: 0;
    padding: 7px 30px 5px 8px;
    border-radius: 3px;
    display: inline-block;
    cursor: pointer;

    i {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    &.second-btn {
      left: 0;
      right: auto;
      color: #000;
      //background: #dd9b19;

      i {
        right: 10px;
        left: auto;
      }
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: #000;
      background: #dd9b19;
    }
  }

  &.nakdan-auto {
    .product-description {
      padding-bottom: 65px;
      height: auto;
    }

    .nakdan-pro {
      border-top: 1px solid #ccc;
      padding-top: 20px;

      .product-description {
        height: 150px;
        line-height: 21px;
      }
    }
  }
}

.he .product-btn {
  right: initial;
  padding: 7px 8px 5px 30px;
  left: 0;

  i {
    right: auto;
    left: 10px;
  }

  &.second-btn {
    right: 0;
    left: auto;

    i {
      right: auto;
      left: 10px;
    }
  }
}

.product-demo {
  position: absolute;
  left: 0;
  color: #000;
  bottom: 15px;
  text-decoration: underline;

  i {
    font-family: FontAwesome;
    font-weight: 400;
  }
}

.product-demo:hover {
  color: #000;
  text-decoration: underline;
}

.he .product-demo {
  left: inherit;
  right: 0;
}

.beta-tools {
  h2 {
    line-height: 22px;
  }

  .beta-logo-wrap {
    width: 80px;
    height: 79px;

    @media only screen and (max-width: 1024px) {
      max-width: 100%;
      width: 60px;
      height: 60px;
    }

    border-radius: 10px;

    img {
      max-height: 50px;
      max-width: 60px !important;

      &#Chrome-Extension-for-Adding-Vocalization-to-the-Web {
        max-width: 100px !important;
        max-height: 67px;
      }

      @media only screen and (max-width: 1024px) {
        max-height: 40px;
        max-width: 50px !important;

        &#Chrome-Extension-for-Adding-Vocalization-to-the-Web {
          max-height: 55px;
        }
      }

      vertical-align: middle;
    }
  }

  a,
  .btn {
    &.demo-link {
      font-size: 16px;
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }

    &:hover {
      text-decoration: none;
    }

    i {
      font-family: FontAwesome;
      font-weight: 400;
    }
  }
}
</style>
