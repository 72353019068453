import Vue from "vue"
import Router from "vue-router"
import Home from "./views/home.vue"
import DictaTools from "./views/dictaTools.vue"
import About from "./views/about.vue"
import Tos from "./views/tos.vue"
import Publications from "./views/publications.vue"
import Developers from "./views/developers.vue"
import Article from "./components/article.vue"
import dictaHeader from "./views/dictaHeader.vue"
import dictaLM from "./views/dictaLM.vue"
import dictaBERT from "./views/dictaBERT.vue"
//import dictaMobileMenu from './views/dictaMobileMenu.vue'

Vue.use(Router)

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/tools",
      name: "tools",
      component: DictaTools,
    },
    {
      path: "/about",
      name: "about",
      component: About,
    },
    {
      path: "/publications",
      name: "publications",
      component: Publications,
    },
    {
      path: "/developers",
      name: "developers",
      component: Developers,
    },
    {
      path: "/dicta-lm",
      name: "dicta-lm",
      component: dictaLM,
    },
    {
      path: "/dicta-bert",
      name: "dicta-bert",
      component: dictaBERT,
    },
    {
      path: "/tos",
      name: "tos",
      component: Tos,
    },
    {
      path: "/dicta-header",
      name: "dicta-header",
      component: dictaHeader,
    },
    {
      path: "/index.html",
      redirect: "/",
    },
    {
      path: "/index-he.html",
      redirect: "/",
    },
    {
      path: "/:href",
      component: Article,
      name: "news update",
    },
    {
      path: "*",
      component: Home,
    },
  ],
})
