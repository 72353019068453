var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dicta-tools-section"},[_c('section',{staticClass:"dicta-tools container pt-5"},[_c('div',{staticClass:"row"},_vm._l((_vm.tools),function(tool,index){return _c('div',{key:index,staticClass:"col-xl-4 col-lg-6",attrs:{"id":tool.engTitle.split(' ').join('_').replace(':', '').toLowerCase()}},[_c('div',{staticClass:"product-wrap",class:{ 'nakdan-auto': tool.hasOwnProperty('href2') }},[_c('a',{staticClass:"tool-link bg-primary d-block product-logo position-relative",attrs:{"href":tool.hasOwnProperty('href')
                ? tool.href
                : _vm.hebrew
                ? tool.hebHref
                : tool.engHref}},[_c('img',{staticClass:"logo",attrs:{"alt":_vm.hebrew ? 'לוגו ' + tool.hebTitle : tool.engTitle + ' logo',"src":tool.logo}})]),_c('a',{staticClass:"tool-title",attrs:{"href":tool.hasOwnProperty('href')
                ? tool.href
                : _vm.hebrew
                ? tool.hebHref
                : tool.engHref}},[_c('h2',{staticClass:"text-body"},[_vm._v(" "+_vm._s(_vm.hebrew ? tool.hebTitle : tool.engTitle)+" ")])]),_c('div',{staticClass:"product-description position-relative"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.hebrew ? tool.hebSubtitle : tool.engSubtitle)}}),(tool.href || tool.hebHref)?_c('a',{staticClass:"product-btn",attrs:{"href":tool.hasOwnProperty('href')
                  ? tool.href
                  : _vm.hebrew
                  ? tool.hebHref
                  : tool.engHref}},[_vm._v(" "+_vm._s(_vm.hebrew ? tool.hebLinkTitle : tool.engLinkTitle)+" "),(_vm.hebrew)?_c('i',{staticClass:"fa fa-arrow-left",attrs:{"aria-hidden":"true"}}):_c('i',{staticClass:"fa fa-arrow-right",attrs:{"aria-hidden":"true"}})]):_vm._e(),(tool.hasOwnProperty('appHref2') && tool.appHref2 !== '#')?_c('a',{staticClass:"product-btn second-btn",attrs:{"href":tool.appHref2}},[_vm._v(" "+_vm._s(_vm.hebrew ? tool.hebLinkTitle2 : tool.engLinkTitle2)+" "),(_vm.hebrew)?_c('i',{staticClass:"fa fa-arrow-left",attrs:{"aria-hidden":"true"}}):_c('i',{staticClass:"fa fa-arrow-right",attrs:{"aria-hidden":"true"}})]):_vm._e(),(tool.hasOwnProperty('appHref2') && tool.appHref2 === '#')?_c('a',{staticClass:"product-btn second-btn text-primary bg-white text-underline px-0",on:{"click":_vm.openForm}},[_vm._v(" "+_vm._s(_vm.hebrew ? tool.hebLinkTitle2 : tool.engLinkTitle2)+" ")]):_vm._e(),(tool.engTitle === 'Nakdan')?_c('b-btn',{staticClass:"product-demo px-0 bg-transparent shadow-none",attrs:{"id":"nakdanDemoLink","variant":"link"},on:{"click":function($event){_vm.showVideo = true
                _vm.vidID = 'FkXcFUH0Ljg'}}},[_c('i',{staticClass:"fa fa-youtube-play",class:{ 'ml-1': _vm.hebrew, 'mr-1': !_vm.hebrew },attrs:{"aria-hidden":"true"}}),_c('span',[_vm._v(_vm._s(_vm.hebrew ? "הדגמה" : "Demo"))])]):_vm._e()],1),(tool.hasOwnProperty('href2'))?_c('div',{staticClass:"nakdan-pro"},[_c('a',{staticClass:"tool-title",attrs:{"href":tool.hasOwnProperty('href2')
                  ? tool.href2
                  : _vm.hebrew
                  ? tool.hebHref2
                  : tool.engHref2}},[_c('h2',{staticClass:"text-body"},[_vm._v(" "+_vm._s(_vm.hebrew ? tool.hebTitle2 : tool.engTitle2)+" ")])]),_c('div',{staticClass:"product-description position-relative"},[_vm._v(" "+_vm._s(_vm.hebrew ? _vm.smallScreen && tool.hebMobileSubtitle2 ? tool.hebMobileSubtitle2 : tool.hebSubtitle2 : _vm.smallScreen && tool.engMobileSubtitle2 ? tool.engMobileSubtitle2 : tool.engSubtitle2)+" "),_c('a',{staticClass:"product-btn",attrs:{"href":tool.hasOwnProperty('href2')
                    ? tool.href2
                    : _vm.hebrew
                    ? tool.hebHref2
                    : tool.engHref2}},[_vm._v(" "+_vm._s(_vm.hebrew ? tool.hebLinkTitle2 : tool.engLinkTitle2)+" "),(_vm.hebrew)?_c('i',{staticClass:"fa fa-arrow-left",attrs:{"aria-hidden":"true"}}):_c('i',{staticClass:"fa fa-arrow-right",attrs:{"aria-hidden":"true"}})]),(tool.engTitle === 'Quick Nakdan')?_c('b-btn',{staticClass:"product-demo px-0 bg-transparent shadow-none",attrs:{"variant":"link"},on:{"click":function($event){_vm.showVideo = true
                  _vm.vidID = 'ux2MZ6VWpyo'}}},[_c('i',{staticClass:"fa fa-youtube-play",class:{ 'ml-1': _vm.hebrew, 'mr-1': !_vm.hebrew },attrs:{"aria-hidden":"true"}}),_c('span',[_vm._v(_vm._s(_vm.hebrew ? "הדגמה" : "Demo"))])]):_vm._e()],1)]):_vm._e()])])}),0)]),_c('section',{staticClass:"dicta-tools beta-tools bg-background pt-3 pb-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"tools-heading text-center"},[_vm._v(" "+_vm._s(_vm.hebrew ? "כלים במעבדה (בטא)" : "Beta Tools")+" ")]),_c('div',{staticClass:"blue-sep"}),_c('div',{staticClass:"row"},_vm._l((_vm.betaTools),function(tool,index){return _c('div',{key:index,staticClass:"col-md-6 py-md-4 mt-3 mt-sm-0",class:(_vm.hebrew && index % 2 == 0) || (!_vm.hebrew && index % 2 != 0)
              ? 'pl-md-4'
              : 'pr-md-4'},[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"d-inline-block",staticStyle:{"vertical-align":"top"}},[_c('a',{attrs:{"href":_vm.hebrew
                    ? tool.hebHref
                    : tool.engHref
                    ? tool.engHref
                    : tool.hebHref}},[_c('div',{staticClass:"beta-logo-wrap d-flex bg-primary",class:_vm.hebrew ? 'ml-3' : 'mr-3'},[_c('img',{staticClass:"logo d-block m-auto",attrs:{"id":tool.engTitle.replaceAll(' ', '-'),"alt":_vm.hebrew
                        ? 'לוגו ' + tool.hebTitle
                        : tool.engTitle + ' logo',"src":tool.logo}})])])]),_c('div',{staticClass:"d-inline-block w-75",staticStyle:{"vertical-align":"top"}},[_c('h2',{staticClass:"mb-1"},[_c('a',{staticClass:"text-body",attrs:{"href":_vm.hebrew
                      ? tool.hebHref
                      : tool.engHref
                      ? tool.engHref
                      : tool.hebHref}},[_vm._v(" "+_vm._s(_vm.hebrew ? tool.hebTitle : tool.engTitle)+" ")])]),_vm._v(" "+_vm._s(_vm.hebrew ? tool.hebText : tool.engText)+" ")])]),(_vm.hebrew)?_c('div',{staticClass:"text-left",attrs:{"dir":_vm.hebrew ? 'rtl' : 'ltr'}},[(tool.demoId)?_c('b-btn',{staticClass:"ml-2 demo-link px-0 bg-transparent shadow-none",attrs:{"variant":"link"},on:{"click":function($event){_vm.showVideo = true
                _vm.vidID = tool.demoId}}},[_c('i',{staticClass:"ml-1 position-relative fa fa-youtube-play",attrs:{"aria-hidden":"true"}}),_c('span',[_vm._v("הדגמה")])]):_vm._e(),_c('a',{staticClass:"text-body border border-dark py-1 px-2 rounded",attrs:{"href":tool.hebHref}},[_vm._v(" "+_vm._s(tool.hebButtonText ? tool.hebButtonText : "התחל")+" "),_c('i',{staticClass:"fa fa-arrow-left",attrs:{"aria-hidden":"true"}})])],1):_c('div',{staticClass:"text-right"},[(tool.demoId)?_c('b-btn',{staticClass:"mr-2 demo-link px-0 bg-transparent shadow-none",attrs:{"variant":"link"},on:{"click":function($event){_vm.showVideo = true
                _vm.vidID = tool.demoId}}},[_c('i',{staticClass:"mr-1 position-relative fa fa-youtube-play",attrs:{"aria-hidden":"true"}}),_c('span',[_vm._v("Demo")])]):_vm._e(),_c('a',{staticClass:"text-body border border-dark py-1 px-2 rounded",attrs:{"href":tool.engHref ? tool.engHref : tool.hebHref}},[_vm._v(" "+_vm._s(tool.engButtonText ? tool.engButtonText : "Start")+" "),_c('i',{staticClass:"fa fa-arrow-right",attrs:{"aria-hidden":"true"}})])],1),_c('hr',{staticClass:"mt-3 my-3"})])}),0)])]),_c('video-modal',{attrs:{"videoId":_vm.vidID},model:{value:(_vm.showVideo),callback:function ($$v) {_vm.showVideo=$$v},expression:"showVideo"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }