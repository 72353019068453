<template>
  <div class="developer-information">
    <div
      class="mb-4 description d-flex justify-content-center text-center text-white align-items-center"
    >
      <h1>{{ hebrew ? "Dicta BERT" : "Dicta BERT" }}</h1>
    </div>
    <b-container v-if="hebrew">
      <p>
        שמחים להציג מודל חדש מסדרת DictaBERT: מודל אחד משותף לסגמנטציה, ניתוח
        מופרולוגי, למטיזציה, ניתוח תחבירי, וזיהוי ישויות! עכשיו, בפעם הראשונה,
        אפשר להריץ משפטים עבריים ישירות ב huggingface ולקבל ניתוח תחבירי
        ומורפולוגי, ניתוח של NER וזיהוי lemmas, והכול בשורת קוד אחת.
      </p>
      <p>
        אפשר להתנסות בדמו כאן, ולקבל ויזוליזציה של הניתוח התחבירי:

        <a href="https://huggingface.co/spaces/dicta-il/joint-demo">
          https://huggingface.co/spaces/dicta-il/joint-demo
        </a>
      </p>
      <p>
        המודל פתוח לקהל הרחב. אפשר להשתמש בו כבר עכשיו בקישור:
        <a href="https://huggingface.co/dicta-il/dictabert-joint"
          >https://huggingface.co/dicta-il/dictabert-joint</a
        >
        <br />
        (דוגמת קוד עם הסברים נמצא בעמוד של המודל).
      </p>
      <p>
        למי שצריך להריץ הרבה תוכן עם מעט כוח חישוב, אנחנו משחררים את אותו המודל
        בגרסת Tiny! המודל בגודל רק 40 מליון פרמטרים ורץ בקלות על מחשב רגיל. אפשר
        לגשת אליו כאן:
        <a href="https://huggingface.co/dicta-il/dictabert-tiny-joint"
          >https://huggingface.co/dicta-il/dictabert-tiny-joint</a
        >
        <br />
        (כמובן המודל לא מגיע לרמת דיוק של מודל הבסיס, אבל מגיע לדיוקים קרובים
        וברבע מכוח המחשוב).
      </p>
      <p>
        המודל מציע כרגע שלוש אופציות לפלט: פורמט JSON, פורמט UD בסטייל של Hebrew
        Treebank, ופורמט UD בסטייל של האיגוד.
      </p>
      <p>
        אחד החידושים של המודל הנוכחי הוא שכל התהליך הוא על בסיס רשת עצבית בלבד
        ואין שום שימוש בלקסיקון או בקובצי מידע חיצוניים. בקרוב נשתף מאמר שלם עם
        כל הפרטים על הביצועים ועל האימון.
      </p>
      <p>
        בנוסף למודל זה אנחנו משחררים גם מודלים ייעודיים ללמטיזציה (<a
          href="https://huggingface.co/dicta-il/dictabert-lex"
          >https://huggingface.co/dicta-il/dictabert-lex</a
        >) ולניתוח תחבירי (<a
          href="https://huggingface.co/dicta-il/dictabert-syntax"
          >https://huggingface.co/dicta-il/dictabert-syntax</a
        >).
      </p>
      <p>
        ג.נ. כאמור המודל כולו ניורוני בלי שום לקסיקון ולכן לעיתים רחוקות מודל
        הלמטיזציה חוזה לקסמה שאיננה מתאימה. אפשר לקרוא יותר כאן:
        <a href="https://huggingface.co/dicta-il/dictabert-lex"
          >https://huggingface.co/dicta-il/dictabert-lex</a
        >
      </p>
    </b-container>
    <b-container v-else>
      <p>
        We are pleased to introduce a new model from the DictaBERT series: a
        single common model for segmentation, morphology analysis,
        lemmatization, syntactic analysis, and entity recognition! Now, for the
        first time, you can run Hebrew sentences directly in huggingface and
        receive syntactic analysis and morphological, NER analysis and lemmas
        detection, all with a single line of code.
      </p>
      <p>
        You can experiment with the demo here, and get a visualization of the
        syntactic analysis:
        <a href="https://huggingface.co/spaces/dicta-il/joint-demo">
          https://huggingface.co/spaces/dicta-il/joint-demo
        </a>
      </p>
      <p>
        The model is open to the public. It can be accessed at the following
        link:
        <a href="https://huggingface.co/dicta-il/dictabert-joint"
          >https://huggingface.co/dicta-il/dictabert-joint</a
        >
        (a code sample with explanations can be found on the model's page).
      </p>
      <p>
        For running large amounts of content with limited computing power, we
        released the same model in the Tiny version! The model is only 40
        million parameters and runs easily on a regular computer, which can be
        accessed here:
        <a href="https://huggingface.co/dicta-il/dictabert-tiny-joint"
          >https://huggingface.co/dicta-il/dictabert-tiny-joint</a
        >
        <br />
        (this model does not reach the level of accuracy of the base model, but
        it does reach close accuracies using a quarter of the computing power).
      </p>
      <p>
        The model currently offers three output options: JSON format, UD format
        in Hebrew style Treebank, and UD format in union style.
      </p>
      <p>
        One of the innovations of the current model is that the entire process
        is based entirely on a neural network, with no use of a lexicon or
        external data files. In the near future, we will release a complete
        article with all of the information about the training and performance.
      </p>
      <p>
        In addition to this model, we will also release dedicated models for
        lemmatization (<a href="https://huggingface.co/dicta-il/dictabert-lex"
          >https://huggingface.co/dicta-il/dictabert-lex</a
        >) and for syntactic analysis (<a
          href="https://huggingface.co/dicta-il/dictabert-syntax"
          >https://huggingface.co/dicta-il/dictabert-syntax</a
        >).
      </p>
      <p>
        Disclaimer: As mentioned, the entire model is neuronal without any
        lexicon; therefore, at times the lemmatization model can predict an
        incorrect lemma. You can read more here:
        <a href="https://huggingface.co/dicta-il/dictabert-lex"
          >https://huggingface.co/dicta-il/dictabert-lex</a
        >
      </p>
    </b-container>
  </div>
</template>
<script>
export default {
  name: "DictaBert",
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
  },
}
</script>
