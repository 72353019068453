<template>
  <div class="publication-wrap bg-white">
    <div class="mb-4 py-2 px-2 px-lg-0 d-lg-flex align-items-center">
      <div class="icon-wrap p-lg-3 mx-2 d-lg-flex justify-content-center">
        <i class="far fa-newspaper align-self-center"></i>
      </div>
      <div class="mx-2">
        <div :dir="hebrew ? 'rtl' : 'ltr'">
          <small
            >{{
              hebrew && publicationItem.nameHE
                ? publicationItem.nameHE
                : publicationItem.name
            }}
            <span v-if="publicationItem.name" class="mx-1">|</span>
            {{ publicationItem.date }}
          </small>
        </div>
        <strong class="authors d-block">{{
          hebrew && publicationItem.authorsHE
            ? publicationItem.authorsHE
            : publicationItem.authors
        }}</strong>
        <h2 class="my-1">
          <a class="text-underline d-block" :href="publicationItem.link">
            {{
              hebrew && publicationItem.textHE
                ? publicationItem.textHE
                : publicationItem.text
            }}
          </a>
        </h2>
      </div>
    </div>
    <!--     <div class="mb-4 p-3 xd-block xd-lg-none">
      <div class="d-flex justify-content-between">
        <i
          style="font-size: 45px"
          class="far fa-newspaper text-primary mt-n2"
        ></i>

      </div>
      <div class="my-3">
        <h3 class="mb-2">
          {{ publicationItem.name }}
          <span v-if="publicationItem.name" class="mx-1">|</span>
          {{ publicationItem.date }}
        </h3>
        <strong class="authors d-block">{{
          hebrew && publicationItem.authorsHE
            ? publicationItem.authorsHE
            : publicationItem.authors
        }}</strong>
      </div>
      <div>
        <a
          class="text-body text-underline d-block"
          :href="publicationItem.link"
        >
          <h2 class="my-1">{{ publicationItem.text }}</h2>
        </a>
      </div>
    </div>-->
  </div>
</template>
<script>
export default {
  name: "DictaPublication",
  props: ["publicationItem"],
  data() {
    return {}
  },

  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
  },
}
</script>
<style scoped lang="scss">
.publication-wrap {
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

  a {
    color: #000;
    &:hover {
      color: var(--primary);
    }
  }

  .authors {
    line-height: 20px;
  }
  .icon-wrap {
    border-radius: 6px;
    align-self: stretch;
    color: white;
    background-color: var(--primary);
    i {
      font-size: 50px;
    }
    @media only screen and (max-width: 991px) {
      color: var(--primary);
      background-color: transparent;
    }
  }

  .apa {
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.32);
    font-size: 13px;
    //  display: none;
  }
}
</style>
