<template>
  <div class="publications bg-background">
    <div class="dicta-publications">
      <div
        class="description d-flex justify-content-center text-center text-white align-items-center"
      >
        <h1>{{ hebrew ? "פרסומים" : "Publications" }}</h1>
      </div>
      <section class="external" v-if="externalPublications.length > 0">
        <div class="tools-heading text-center mt-4">
          {{ hebrew ? "פרסומים מדעיים" : "Scientific publications" }}
        </div>
        <div class="blue-sep"></div>
        <b-container class="pb-4">
          <span
            v-for="(publication, index) in visibleExternalPublications"
            :key="index"
          >
            <dicta-publication
              :publicationItem="publication"
            ></dicta-publication>
          </span>
          <!--           <div class="text-center pt-3">
            <b-btn class="bg-transparent border-0 mx-auto shadow-none text-primary"
              @click="showAllExternalPublications = !showAllExternalPublications" v-if="externalPublications.length > 2">
              <small v-if="showAllExternalPublications">
                <u>{{ hebrew ? 'הצג פחות' : 'Show less' }}</u>
              </small>
              <small class="text-primary" v-else>
                <u>{{ hebrew ? 'פרסומים נוספים' : 'Show more' }}</u>
              </small>
            </b-btn>
          </div> -->
        </b-container>
      </section>
      <section
        class="internal bg-white py-4"
        v-if="internalPublications.length > 0"
      >
        <div class="tools-heading text-center">
          {{ hebrew ? "פרסומים אחרים" : "Other publications" }}
        </div>
        <div class="blue-sep"></div>
        <b-container>
          <span
            v-for="(publication, index) in visibleInternalPublications"
            :key="index"
          >
            <dicta-publication
              :publicationItem="publication"
            ></dicta-publication>
          </span>
        </b-container>
      </section>
    </div>
  </div>
</template>
<script>
import DictaPublication from "@/components/dictaPublication"
import { publications } from "./publicationList"
export default {
  name: "Publications",
  components: {
    DictaPublication,
  },
  data() {
    return {
      publications,
      internalPublications: publications.filter(
        (publication) => publication.category === "Internal"
      ),
      externalPublications: publications.filter(
        (publication) => publication.category !== "Internal"
      ),
      showAllExternalPublications: true,
      showAllInternalPublications: true,
    }
  },

  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    visibleExternalPublications() {
      return this.showAllExternalPublications
        ? this.externalPublications
        : this.externalPublications.slice(0, 2)
    },
    visibleInternalPublications() {
      return this.showAllInternalPublications
        ? this.internalPublications
        : this.internalPublications.slice(0, 2)
    },
  },
}
</script>
