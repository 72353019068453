<template>
  <form
    @submit="formSubmitted"
    ref="contactForm"
    action="https://formspree.io/f/xvovdkvj"
    method="POST"
  >
    <div class="container">
      <div class="holder">
        <h1 class="mb-3">{{ hebrew ? "צרו קשר" : "Contact Us" }}</h1>
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <input
              class="form-control"
              required
              name="name"
              type="text"
              :placeholder="hebrew ? 'שם' : 'Name'"
            />
          </div>
          <div class="col-xs-12 col-sm-6">
            <input
              class="form-control"
              required
              name="_replyto"
              type="email"
              :placeholder="hebrew ? 'דואר אלקטרוני [שדה חובה]' : 'E-mail'"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12">
            <textarea
              class="form-control"
              name="message"
              required
              :placeholder="hebrew ? 'תאור' : 'Description'"
            ></textarea>
          </div>
        </div>
        <button type="submit" class="bt bt-sm">
          {{ hebrew ? "שלח" : "Send" }}
        </button>
      </div>
    </div>
  </form>
</template>
<script>
export default {
  name: "Form",
  methods: {
    formSubmitted() {
      this.$refs.contactForm.reset()
    },
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
  },
}
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 10241px) {
  form {
    height: 100%;

    .container {
      height: 100%;
    }
  }

  h1 {
    text-align: center;
    font-size: 23px !important;
    margin: 0 0 40px !important;
  }
}

input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
input[type="submit"],
select,
textarea,
.form-control {
  height: 50px;
  border-radius: 4px;
  border: solid 2px #000000;
  background-color: #ffffff !important;
  margin: 0 0 19px;
  color: #000;
  font-weight: 400;
  box-shadow: none;
  padding: 5px 12px;
  font-size: 16px;
  line-height: 20px;
  outline: none;
  width: 100%;
  font-family: "Arimo", sans-serif;
}

textarea {
  resize: none;
  min-height: 100px;

  @media only screen and (max-width: 1024px) {
    min-height: 100px;
  }
}

input[type="text"]:hover,
input[type="password"]:hover,
input[type="datetime"]:hover,
input[type="datetime-local"]:hover,
input[type="date"]:hover,
input[type="month"]:hover,
input[type="time"]:hover,
input[type="week"]:hover,
input[type="number"]:hover,
input[type="email"]:hover,
input[type="url"]:hover,
input[type="search"]:hover,
input[type="tel"]:hover,
input[type="color"]:hover,
input[type="submit"]:hover,
select:hover,
textarea:hover {
  background-color: #fff;
}

input[type="search"]:visited,
input[type="search"]:active,
input[type="search"]:focus {
  width: 100%;
  background-color: #fbfbfb;
  border: none;
  border-bottom: 1px solid #979797;
  box-shadow: none;
}

#header.white input[type="search"]:visited,
#header.white input[type="search"]:active,
#header.white input[type="search"]:focus,
#header.white input[type="search"] {
  background-color: #fff;
}

.form-control:focus {
  border-color: #000000;
  outline: 0;
  box-shadow: none;
}

.form-group > .form-control {
  margin-bottom: 0;
}

::-webkit-input-placeholder {
  color: #000;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #000;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #000;
}

:-ms-input-placeholder {
  color: #000;
}

input.has-icon,
select.has-icon,
textarea.has-icon {
  padding-left: 35px;
}

.bt-sm {
  height: 40px;
  border-radius: 4px;

  @media only screen and (max-width: 1024px) {
    position: absolute;
    bottom: 25px;
    width: 100% !important;
  }
}

.bt:hover {
  text-decoration: none;
}

.bt-md {
  font-size: 24px;
  line-height: 1.5;
  height: 25px;
}

.bt-lg {
  margin: 0 0 11px;
  width: 170px;
  height: 50px;
  border-radius: 2px;
  background-color: #007ee5;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
}

.bt {
  display: inline-block;
  vertical-align: middle;
  width: 96px;
  height: 40px;
  border-radius: 2px;
  background-color: #ffa900;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 3px;
}

.bt-sm {
  font-size: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.96);
}

.holder {
  padding: 25px 0 29px;
  position: relative;

  @media only screen and (max-width: 1024px) {
    height: 100%;
  }
}

.holder:after,
.holder:before {
  bottom: -11px;
  left: 34%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;

  @media only screen and (max-width: 1200px) {
    left: 42.5%;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.holder:before {
  border-color: rgba(216, 216, 216, 0);
  border-top-color: #d8d8d8;
  border-width: 12px;
}

.holder:after {
  border-top-color: #fff;
  border-width: 10px;
  margin-left: 2px;
  bottom: -7px;
}

.he .holder:after,
.he .holder:before {
  left: inherit;
  right: 28%;

  @media only screen and (max-width: 1200px) {
    right: 33%;
  }
}

.he .holder:after {
  margin-left: 0;
  margin-right: 2px;
}
</style>
