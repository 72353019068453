import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    selectedNewsItem: "",
  },
  mutations: {
    SET_NEWS_ITEM(store, val) {
      store.selectedNewsItem = val
    },
  },
})

export default store
