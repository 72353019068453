<template>
  <div class="tos container text-left" style="direction:ltr">
     <h1 class="f black">Dicta Terms of Service</h1>
        <p>Last modified: 27 November 2017</p>
        <h2>Welcome to Dicta!</h2>
        <p>Thanks for using our products and services (“Services”). The Services are provided by Dicta
            R.A. (“Dicta”), located at 8 Am Veolamo Street, Jerusalem, Israel 9546306.</p>
        <p>By using our Services, you are agreeing to these terms. Please read them carefully.</p>
        <h2>Using our Services</h2>
        <p>You must follow any policies made available to you within the Services.</p>
        <p>Do not misuse our Services, for example, do not interfere with our Services or try to access
            them using a method other than the interface and the instructions that we provide. You may
            use our Services only as permitted by law. We may suspend or stop providing our Services to
            you if you do not comply with our terms or policies.</p>
        <p>Using our Services does not give you ownership of any intellectual property rights in our
            Services or the content that you access. You may not use content from our Services unless
            you obtain permission from its owner or are otherwise permitted by law. These terms do not
            grant you the right to use any branding or logos used in our Services. Do not remove,
            obscure or alter any legal notices displayed in or along with our Services.</p>
        <p>Our Services may display some content that is not Dicta’s. This content is the sole
            responsibility of the entity that makes it available. We may review content to determine
            whether it is illegal or violates our policies, and we may remove or refuse to display
            content that we reasonably believe violates our policies or the law. But that does not
            necessarily mean that we review content, so please do not assume that we do.</p>
        <p>In connection with your use of the Services, we may send you service announcements,
            administrative messages and other information. You may opt out of some of those
            communications.</p>
        <h2>Your Dicta Account</h2>
        <p>You may need a Dicta Account in order to use some of our Services. You may create your own
            Dicta Account, or your Dicta Account may be assigned to you by an administrator, such as
            your employer or educational institution. If you are using a Dicta Account assigned to you
            by an administrator, different or additional terms may apply, and your administrator may be
            able to access or disable your account.</p>
        <p>To protect your Dicta Account, keep your password confidential. You are responsible for the
            activity that happens on or through your Dicta Account. Try not to reuse your Dicta Account
            password on third-party applications. If you learn of any unauthorised use of your password
            or Dicta Account, please contact us.</p>
        <h2>Privacy and Copyright Protection</h2>
        <p>Dicta’s Privacy Policies explain how we treat your personal data and protect your privacy
            when you use our Services. By using our Services, you agree that Dicta can use such data in
            accordance with our Privacy Policies.</p>
        <p>We may respond to notices of alleged copyright infringement and terminate accounts of repeat
            infringers.</p>
        <h2>Your Content in our Services</h2>
        <p>Some of our Services allow you to upload, submit, store, send or receive content. You retain
            ownership of any intellectual property rights that you hold in that content. In short, what
            belongs to you stays yours.</p>
        <p>When you upload, submit, store, send or receive content to or through our Services, you give
            Dicta (and those we work with) a worldwide licence to use, host, store, reproduce, modify,
            create derivative works (such as those resulting from translations, adaptations or other
            changes that we make so that your content works better with our Services), communicate,
            publish, publicly perform, publicly display and distribute such content. The rights that you
            grant in this licence are for the limited purpose of operating, promoting and improving our
            Services, and to develop new ones. This licence continues even if you stop using our
            Services.</p>
        <p>Our automated systems analyse your content to provide you with personally relevant product
            features. This analysis occurs as the content is sent, received and when it is stored.</p>
        <p>If you have a Dicta Account, we may display your Profile name, Profile photo and actions you
            take on Dicta or on third-party applications connected to your Dicta Account in our
            Services.</p>
        <p>You can find more information about how Dicta uses and stores content in the Privacy Policy
            or additional terms for particular Services. If you submit feedback or suggestions about our
            Services, we may use your feedback or suggestions without obligation to you.</p>
        <h2>About Software in our Services</h2>
        <p>When a Service requires or includes downloadable software, this software may be updated
            automatically on your device once a new version or feature is available. Some Services may
            let you adjust your automatic update settings.</p>
        <p>Dicta gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive licence
            to use the software provided to you by Dicta as part of the Services. This licence is for
            the sole purpose of enabling you to use and enjoy the benefit of the Services as provided by
            Dicta in the manner permitted by these terms. You may not copy, modify, distribute, sell or
            lease any part of our Services or included software, nor may you reverse engineer or attempt
            to extract the source code of that software, unless laws prohibit those restrictions or you
            have our written permission.</p>
        <p>Open-source software is important to us. Some software used in our Services may be offered
            under an open-source licence that we will make available to you. There may be provisions in
            the open-source licence that expressly override some of these terms.</p>
        <h2>Modifying and Terminating our Services</h2>
        <p>We are constantly changing and improving our Services. We may add or remove functionalities
            or features and we may suspend or stop a Service altogether.</p>
        <p>You can stop using our Services at any time, although we would be sorry to see you go. Dicta
            may also stop providing Services to you or add or create new limits to our Services at any
            time.</p>
        <p>We believe that you own your data, and preserving your access to such data is important. If
            we discontinue a Service, where reasonably possible, we will give you reasonable advance
            notice and a chance to remove information from that Service.</p>
        <h2>Our Warranties and Disclaimers</h2>
        <p>We provide our Services using a commercially reasonable level of skill and care and we hope
            that you will enjoy using them. But there are certain things that we do not promise about
            our Services.</p>
        <p>OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS OR ADDITIONAL TERMS, NEITHER DICTA NOR ITS
            SUPPLIERS OR DISTRIBUTORS MAKES ANY SPECIFIC PROMISES ABOUT THE SERVICES. FOR EXAMPLE, WE DO
            NOT MAKE ANY COMMITMENTS ABOUT THE CONTENT WITHIN THE SERVICES, THE SPECIFIC FUNCTIONS OF
            THE SERVICES OR THEIR RELIABILITY, AVAILABILITY OR ABILITY TO MEET YOUR NEEDS. WE PROVIDE
            THE SERVICES “AS IS”.</p>
        <p>SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE IMPLIED WARRANTY OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. TO THE EXTENT
            PERMITTED BY LAW, WE EXCLUDE ALL WARRANTIES.</p>
        <h2>Liability for our Services</h2>
        <p>WHEN PERMITTED BY LAW, DICTA AND DICTA’S SUPPLIERS AND DISTRIBUTORS WILL NOT BE RESPONSIBLE
            FOR LOST PROFITS, REVENUES OR DATA, FINANCIAL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL,
            EXEMPLARY OR PUNITIVE DAMAGES.</p>
        <p>TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF DICTA AND ITS SUPPLIERS AND
            DISTRIBUTORS FOR ANY CLAIMS UNDER THESE TERMS, INCLUDING FOR ANY IMPLIED WARRANTIES, IS
            LIMITED TO THE AMOUNT THAT YOU PAID US TO USE THE SERVICES (OR, IF WE CHOOSE, TO SUPPLYING
            YOU WITH THE SERVICES AGAIN).</p>
        <p>IN ALL CASES, DICTA AND ITS SUPPLIERS AND DISTRIBUTORS WILL NOT BE LIABLE FOR ANY LOSS OR
            DAMAGE THAT IS NOT REASONABLY FORESEEABLE.</p>
        <h2>Corporate uses of our Services</h2>
        <p>If you are using our Services on behalf of a Corporate, that Corporate accepts these terms.
            It will hold harmless and indemnify Dicta and its affiliates, officers, agents and employees
            from any claim, action or proceedings arising from or related to the use of the Services or
            violation of these terms, including any liability or expense arising from claims, losses,
            damages, judgements, litigation costs and legal fees.</p>
        <h2>Creative Commons</h2>
        <p>Any use of our Services will be in accordance to Attribution-NonCommercial-ShareAlike 4.0
            International Creative Commons <a class="f black" style="text-decoration: underline;"
                                                href="https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode"
                                                >license</a>.</p>
        <h2>About these Terms</h2>
        <p>We may modify these terms or any additional terms that apply to a Service to, for example,
            reflect changes to the law or changes to our Services. You should look at the terms
            regularly. We’ll post notice of modifications to these terms on this page. Changes will not
            apply retrospectively and will become effective no earlier than fourteen days after they are
            posted. However, changes addressing new functions for a Service or changes made for legal
            reasons will be effective immediately. If you do not agree to the modified terms for a
            Service, you should discontinue your use of that Service.</p>
        <p>If there is any inconsistency between these terms and the additional terms, the additional
            terms will prevail to the extent of the inconsistency.</p>
        <p>These terms govern the relationship between Dicta and you. They do not create any third party
            beneficiary rights.</p>
        <p>If you do not comply with these terms and we do not take action immediately, this doesn’t
            mean that we are giving up any rights that we may have (such as taking action in the
            future).</p>
        <p>If it turns out that a particular term is not enforceable, this will not affect any other
            terms.</p>
        <p>The laws of Israel alone will apply to any disputes arising out of or relating to these terms
            or the Services. All claims arising out of or relating to these terms or the Services will
            be litigated exclusively in the state courts of Jerusalem, Israel, and you and Dicta consent
            to personal jurisdiction in those courts.</p>
        <h2>Welcome to the Dicta Privacy Policy</h2>
        <p>Last modified: 2 October 2017</p>
        <p>There are many different ways that you can use our services. When you share information with
            us, for example by creating a Dicta Account, we can make those services even better. As you
            use our services, we want you to be clear how we’re using information and the ways in which
            you can protect your privacy.</p>
        <p>Our Privacy Policy explains:</p>
        <ul>
            <li>What information we collect and why we collect it.</li>
            <li>How we use that information.</li>
            <li>The choices we offer, including how to access and update information</li>
        </ul>
        <h2>Information that we collect</h2>
        <p>We collect information to provide better services to all of our users.</p>
        <p>We collect information in the following ways:</p>
        <ul>
            <li>Information you give us. For example, many of our services require you to sign up for a
                Dicta Account. When you do, we’ll ask for personal information, like your name, email
                address, telephone number etc. to store with your account.
            </li>
            <li>Information we get from your use of our services. We collect information about the
                services that you use and how you use them. This information includes:
                <ol>
                    <li>Device information</li>
                    <li>We collect device-specific information (such as your hardware model, operating
                        system version, unique device identifiers etc.. Dicta may associate your device
                        identifiers with your Dicta Account.
                    </li>
                    <li>Log information</li>
                    <li>When you use our services or view content provided by Dicta, we automatically
                        collect and store certain information in server logs. This includes:
                        <ul>
                            <li>details of how you used our service, such as your search queries.</li>
                            <li>Internet protocol address.</li>
                            <li>cookies that may uniquely identify your browser or your Dicta Account.
                            </li>
                        </ul>
                    </li>
                </ol>
            </li>
            <li>Unique application numbers</li>
            <li>Certain services include a unique application number. This number and information about
                your installation (for example, the operating system type and application version
                number) may be sent to Dicta when you install or uninstall that service or when that
                service periodically contacts our servers, such as for automatic updates.
            </li>
            <li>Cookies and similar technologies</li>
            <li>We use various technologies to collect and store information when you visit a Dicta
                service, and this may include using cookies or similar technologies to identify your
                browser or device.
            </li>
        </ul>
        <p>Information we collect when you are signed in to Dicta, in addition to information we obtain
            about you from partners, may be associated with your Dicta Account. When information is
            associated with your Dicta Account, we treat it as personal information.</p>
        <h2>How we use information that we collect</h2>
        <p>We use the information we collect from all of our services to provide, maintain, protect and
            improve them, to develop new ones and to protect Dicta and our users. We may also use this
            information to offer you tailored content.</p>
        <p>We may use the name that you provide for your Dicta Profile across all of the services we
            offer that require a Dicta Account. In addition, we may replace past names associated with
            your Dicta Account, so that you are represented consistently across all our services.</p>
        <p>If you have a Dicta Account, we may display your Profile name, Profile photo, and actions you
            take on Dicta or on third-party applications connected to your Dicta Account. When you
            contact Dicta, we keep a record of your communication to help solve any issues you might be
            facing. We may use your email address to inform you about our services, such as letting you
            know about upcoming changes or improvements.</p>
        <p>We use information collected from cookies and other technologies, like pixel tags, to improve
            your user experience and the overall quality of our services.</p>
        <p>Our automated systems analyse your content to provide you personally relevant product
            features.</p>
        <p>We may combine personal information from one service with information, including personal
            information, from other Dicta services – for example, to make it easier to share things with
            people you know. Depending on your account settings, your activity on other sites and apps
            may be associated with your personal information in order to improve Dicta’s services.</p>
        <p>We will ask for your consent before using information for a purpose other than those set out
            in this Privacy Policy.</p>
        <p>Dicta may processes personal information on our servers in many countries around the world.
            We may process your personal information on a server located outside the country where you
            live.</p>

        <h2>Information that you share</h2>
        <p>Our services may let you share information with others. Remember that when you share
            information publicly, it may be indexable by search engines. Our services may provide you
            with different options on sharing and removing your content.</p>
        <h2>Accessing and updating your personal information</h2>
        <p>Whenever you use our services, we aim to provide you with access to your personal
            information. If that information is wrong, we strive to give you ways to update it quickly
            or to delete it – unless we have to keep that information for legitimate business or legal
            purposes. When updating your personal information, we may ask you to verify your identity
            before we can act on your request.</p>
        <p>We may reject requests that are unreasonably repetitive, require disproportionate technical
            effort (for example, developing a new system or fundamentally changing an existing
            practice), risk the privacy of others, or would be extremely impractical (for instance,
            requests concerning information residing on backup systems).</p>
        <p>Where we can provide information access and correction, we will do so free of charge, except
            where it would require a disproportionate effort. We aim to maintain our services in a
            manner that protects information from accidental or malicious destruction. Because of this,
            after you delete information from our services, we may not immediately delete residual
            copies from our active servers and may not remove information from our backup systems.</p>

        <h2>Information that we share</h2>
        <p>We do not share personal information with companies, organisations and individuals outside of
            Dicta unless one of the following circumstances applies:</p>

        <ul>
            <li>With your consent</li>
            <li>We will share personal information with companies, organisations or individuals outside
                Dicta when we have your consent to do so. We require opt-in consent for the sharing of
                any sensitive personal information.
            </li>
            <li>With domain administrators</li>
            <li>If your Dicta Account is managed for you by a domain administrator then your domain
                administrator and resellers who provide user support to your organisation may have
                access to your Dicta Account information. Your domain administrator may be able to:
                <ol>
                    <li>view statistics regarding your account.</li>
                    <li>change your account password.</li>
                    <li>suspend or terminate your account access.</li>
                    <li>access or retain information stored as part of your account.</li>
                    <li>receive your account information in order to satisfy applicable law, regulation,
                        legal process or enforceable governmental request.
                    </li>
                    <li>restrict your ability to delete or edit information or privacy settings.</li>
                </ol>
            </li>
            <li>For external processing</li>
            <li>We may provide personal information to our affiliates or other trusted businesses or
                persons to process it for us, based on our instructions and in compliance with our
                Privacy Policy and any other appropriate confidentiality and security measures.
            </li>
            <li>For legal reasons</li>
            <li>We will share personal information with companies, organisations or individuals outside
                Dicta if we have a belief in good faith that access, use, preservation or disclosure of
                the information is reasonably necessary to:
                <ol>
                    <li>meet any applicable law, regulation, legal process or enforceable governmental
                        request.
                    </li>
                    <li>enforce applicable Terms of Service, including investigation of potential
                        violations.
                    </li>
                    <li>detect, prevent or otherwise address fraud, security or technical issues.</li>
                    <li>protect against harm to the rights, property or safety of Dicta, our users or
                        the public, as required or permitted by law.
                    </li>
                </ol>
            </li>
        </ul>
        <p>We may share non-personally identifiable information publicly and with our partners – like
            publishers or connected sites. For example, we may share information publicly to show trends
            about the general use of our services.</p>
        <p>If Dicta is involved in a merger or acquisition of any type, we will continue to ensure the
            confidentiality of any personal information and give affected users notice before personal
            information is transferred or becomes subject to a different privacy policy.</p>

        <h2>Information security</h2>
        <p>We work hard to protect Dicta and our users from unauthorised access to or unauthorised
            alteration, disclosure or destruction of information that we hold. In particular:</p>
        <ul>
            <li>We review our information collection, storage and processing practices, including
                physical security measures, to guard against unauthorised access to systems.
            </li>
            <li>We restrict access to personal information to Dicta employees, contractors and agents
                who need to know that information in order to process it for us and who are subject to
                strict contractual confidentiality obligations. They may be disciplined or their
                contract terminated if they fail to meet these obligations.
            </li>
        </ul>

        <h2>When this Privacy Policy applies</h2>
        <p>Our Privacy Policy applies to all of the services offered by Dicta R.A. and its affiliates
            and services offered on other sites, but excludes services that have separate privacy
            policies that do not incorporate this Privacy Policy.</p>
        <p>Our Privacy Policy does not apply to services offered by other companies or individuals,
            sites that may include Dicta services or other sites linked from our services. Our Privacy
            Policy does not cover the information practices of other companies and organisations that
            advertise our services and that may use cookies, pixel tags and other technologies to serve
            and offer relevant ads.</p>
        <h2>Changes</h2>
        <p>Our Privacy Policy may change from time to time. We will not reduce your rights under this
            Privacy Policy without your explicit consent. We will post any Privacy Policy changes on
            this page and, if the changes are significant, we will provide a more prominent notice
            (including, for certain services, email notification of Privacy Policy changes). We will
            also keep prior versions of this Privacy Policy in an archive for your review.</p>


                
  </div>
</template>
<script>
export default {
  name: 'Tos',
  components: { 
    
  },
  data () {
    return {
      
    }
  },
  mounted () {
    document.getElementById('top-actions').setAttribute("style", "visibility:hidden;")
    document.getElementById('navigation').setAttribute("style", "visibility:hidden;")
  },
  computed: {
    hebrew () {
      return this.$settings.hebrew
    }
}    
    
}
</script>

