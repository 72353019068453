<template>
  <b-modal v-model="show"
    :hide-footer="showFormSubmitted"
    :dialog-class="isMobile ? 'm-0 h-100' : ''"
    :content-class="isMobile ? 'mobile-display' : ''"
    :no-fade="isMobile"
    :header-class="isMobile ? 'py-1' : ''"
    @hidden="$emit('modal-closed')" :dir="hebrew ? 'rtl' : 'ltr'" id="contact-us"
    ref="contact-modal" class="contact-us" centered :ok-title="hebrew ? 'שלח' : 'Send'"
    :cancel-title="hebrew ? 'ביטול' : 'Cancel'" @ok="submit">
    <template slot="modal-header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div v-if="!isMobile"><i class="far fa-envelope"></i><span class="mx-1">{{ hebrew ? 'צרו קשר' : 'Contact Us' }}</span></div>
        <h3 v-else class="m-0">{{ hebrew ? 'צרו קשר' : 'Contact Us' }}</h3>
        <div @click="closeModal" v-if="isMobile"><h1 class="m-0">&times;</h1></div>
      </div>
    </template>
    <div v-if="showFormSubmitted" class="text-center">
      {{ hebrew ? 'פנייתך התקבלה ותטופל בהקדם' : 'Your request has been received and will be handled as soon as possible'}}
    </div>
    <div :dir="hebrew ? 'rtl' : 'ltr'" 
     v-show="!showFormSubmitted"   
    :class="hebrew ? 'text-right' : 'text-left'" id='zohoSupportWebToCase'>
      <form :class="{ 'was-validated': submitted }" ref="contact-form" 
        id='zsWebToCase_865513000000272009' action='https://desk.zoho.com/support/WebToCase' method='POST'
        enctype='multipart/form-data'>
        <input type='hidden' name='xnQsjsdp' 
        value='edbsn2bef7913d8823d2877c0b2b3286d6358' />
        <input type='hidden' name='xmIwtLD'
          value='edbsn89b42cda35670cbfdbcd975fe798df31a7fa08c538d1f1f47250ea67af4f26ab' /> <input type='hidden'
          name='xJdfEaS' value='' /> <input type='hidden' name='actionType' value='Q2FzZXM=' /> <input type="hidden"
          id="property(module)" value="Cases" /> 
          <input type="hidden" id="dependent_field_values_Cases"
          value="&#x7b;&quot;JSON_VALUES&quot;&#x3a;&#x7b;&#x7d;,&quot;JSON_SELECT_VALUES&quot;&#x3a;&#x7b;&#x7d;,&quot;JSON_MAP_DEP_LABELS&quot;&#x3a;&#x5b;&#x5d;&#x7d;" />
        <input type='hidden' name='returnURL' value='https://dicta.org.il/dicta-header' />

        <b-form-group :label="hebrew ? 'שם' : 'Name'" label-for="Contact Name">
          <b-form-input v-model="name" required name='Contact Name' />
        </b-form-group>

        <b-form-group :label="hebrew ? 'דואר אלקטרוני' : 'Email'" label-for="Email">
          <b-form-input v-model="email" required name='Email' type='text' />
        </b-form-group>

        <b-form-group :label="hebrew ? 'תיאור' : 'Description'" label-for="Description">
          <b-textarea v-model="description" required name='Description' rows="4"></b-textarea>
        </b-form-group>

        <input type='text' name='Subject' :value="'New submission from: ' + siteUrl" class='d-none' />
        <div><small>{{ hebrew ? 'מופעל על ידי' : 'Powered by' }} </small><a target='_blank' rel='noopener noreferrer'
            href='https://zoho.com/desk'><img class='wb_logo' style="height: 25px;"
              src='https://d1ydxa2xvtn0b5.cloudfront.net/app/images/portalLogo.de847024ebc0131731a3.png' /></a>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios'

export default {
  name: 'contactUs',
  props: ['hebrew', 'siteUrl', 'value', 'isMobile'],
  data() {
    return {
      name: '',
      email: '',
      submitted: false,
      description: '',
      showFormSubmitted: false,
      oauthConfig: {
        clientId: '1000.7M0NQT1YTK8IGKT2Q1MJ0ZEK2DMPBK',
        redirectUri: 'https://dicta.org.il/',
        scope: 'Desk.tickets.ALL',
        authorizationUrl: 'https://accounts.zoho.com/oauth/v2/auth',
        tokenUrl: 'https://accounts.zoho.com/oauth/v2/token',
      },
    }
  },
  mounted() {
    const script = document.createElement('script')
    script.src = 'https://d17nz991552y2g.cloudfront.net/app/js/jqueryandencoder.ffa5afd5124fbedceea9.js'
    script.async = true
    document.head.appendChild(script)
  },
  methods: {
    generateNonce() {
      return Math.random().toString(36).substring(2, 15);
    },
    sendForm() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (!code) {
        // Code not found, initiate OAuth 2.0 login
        const nonce = this.generateNonce();
        const authorizeUrl = `${this.oauthConfig.authorizationUrl}?client_id=${this.oauthConfig.clientId}&redirect_uri=${this.oauthConfig.redirectUri}&scope=${this.oauthConfig.scope}&response_type=code&state=${nonce}`;
        window.location.href = authorizeUrl;
      } else {
        // Code found, obtain access token
        const tokenData = {
          client_id: this.oauthConfig.clientId,
          client_secret: this.oauthConfig.clientSecret,
          redirect_uri: this.oauthConfig.redirectUri,
          code: code,
          grant_type: this.oauthConfig.grantType,
        };

        axios
          .post(this.oauthConfig.tokenUrl, tokenData)
          .then((tokenResponse) => {
            const accessToken = tokenResponse.data.access_token;

            // Access token obtained, proceed to send the form
            const ticketData = {
              subject: 'New submission from: ' + this.siteUrl,
              description: this.description,
              contact: {
                email: this.email,
                lastName: this.name,
              },
              departmentId: '865513000000006907',
            };

            const headers = {
              Authorization: `Zoho-oauthtoken ${accessToken}`,
              orgId: '812466914',
              'Content-Type': 'application/json',
            };
            const apiUrl = 'api/'
            axios
              .post(apiUrl, ticketData, { headers })
              .then((ticketResponse) => {
                console.log('Ticket created successfully:', ticketResponse.data);
              })
              .catch((ticketError) => {
                console.error('Error creating ticket:', ticketError);
              });
          })
          .catch((tokenError) => {
            console.error('Error obtaining access token:', tokenError);
          });
      }
    },
    xsendForm() {
      //const apiUrl = 'https://desk.zoho.com/api/v1/tickets'
      const apiUrl = 'api/'
      const ticketData = {
        subject: 'New submission from: ' + this.siteUrl,
        description: this.description,
        contact: {
          email: this.email,
          lastName: this.name
        },
        departmentId: '865513000000006907',
      };

      const headers = {
        Authorization: 'Zoho-oauthtoken 1000.743c1ee596f78c857774b61297c69904.d024529fd0d5afc155839a32c8a34893',
        orgId: '812466914',
        'Content-Type': 'application/json',
      };
      axios
      .post(apiUrl, ticketData, { headers })
      .then((ticketResponse) => {
        console.log('Ticket created successfully:', ticketResponse.data);
      })
      .catch((ticketError) => {
        console.error('Error creating ticket:', ticketError);
      })
    },
    closeModal() {
      this.$refs['contact-modal'].hide()
      this.$emit('modal-closed')
    },
    submit(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.submitted = true
      if (this.$refs['contact-form'].checkValidity()) {
        this.showFormSubmitted = true
        setTimeout(() => { 
          this.sendForm()         
          //this.$refs['contact-form'].submit()
          this.showFormSubmitted = false          
          this.$refs['contact-modal'].hide()
          this.$emit('modal-closed')
          this.$emit('mobile-modal-closed')
        }, 3000)        
      }
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
}
</script>
<style lang="scss">
  .mobile-display {
    height: 95vh;
    footer {
      .btn-secondary {display: none;}
      .btn-primary {
        width: 100%;
        font-size: 18px;
        height: 42px;
      }
    }
  }
</style>