<template>
  <div id="app" class="d-md-flex flex-column">
    <Header v-if="!embedded" @ChangeLanguage="changeSiteLanguage"></Header>
    <router-view></router-view>
    <Footer v-if="!embedded" @ChangeLanguage="changeSiteLanguage"></Footer>
  </div>
</template>

<script>
//import { settings } from "./vue.config"
import Footer from "./components/footer"
import Header from "./components/header"

export default {
  name: "app",
  components: {
    Footer,
    Header,
  },
  data() {
    return {}
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    embedded() {
      return this.$route.name === "dicta-header" // || this.$route.name === 'dicta-mobile-menu'
    },
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search)
    const lang = urlParams.get("lang")
    if (this.$cookies.get("DICTA_USE_HEBREW") !== null) {
      this.$settings.hebrew = this.$cookies.get("DICTA_USE_HEBREW") === "true"
    } else if (lang !== null) {
      this.$settings.hebrew = lang.includes("he")
    } else {
      this.$settings.hebrew = true
    }
    if (this.$settings.hebrew) {
      document.body.classList.add("he")
    } else {
      document.body.classList.remove("he")
    }
  },
  mounted() {},
  methods: {
    changeSiteLanguage() {
      this.$settings.hebrew = !this.$settings.hebrew
      this.$cookies.set(
        "DICTA_USE_HEBREW",
        this.$settings.hebrew,
        -1,
        "/",
        "dicta.org.il"
      )
      this.$cookies.set("DICTA_USE_HEBREW", this.$settings.hebrew, -1, "/", "")
      if (this.$settings.hebrew) {
        document.body.classList.add("he")
      } else {
        document.body.classList.remove("he")
        //history.replaceState && history.replaceState(
        //  null, '', location.pathname + location.search.replace(/[?&]lang=[^&]+/, '').replace(/^&/, '?')
        //)
      }
    },
  },
}
</script>

<style lang="scss">
@font-face {
  font-family: "dicta";
  src: url("assets/fonts/Dicta-Medium.otf") format("opentype");
}

@font-face {
  font-family: "dicta";
  font-weight: bold;
  src: url("assets/fonts/Dicta-Bold.otf") format("opentype");
}

.f-dicta {
  font-family: Roboto, dicta, sans-serif;
  line-height: 1.5;
  letter-spacing: 0.6;
}

#app {
  font-size: 16px;

  img {
    max-width: 100%;
    height: auto;
  }

  .tools-heading {
    margin: 30px auto 0;
    font-size: 32px;
    @media only screen and (max-width: 991px) {
      font-size: 22px;
    }

    &.f-dicta {
      font-size: 41px;
    }
  }

  .blue-sep {
    width: 67px;
    height: 3px;
    background: #017de5;
    margin: 0 auto 30px;
  }

  .description {
    background: url("assets/banner-img.png");
    height: 160px;
    font-size: 32px;

    .vid-btn {
      border: solid 1px #ffffff;
      color: white;

      &:hover {
        background: white !important;
        color: black;
      }
    }
  }

  .vid-btn {
    font-size: 18px;
    border-radius: 3px;
    border: solid 1px #000000;
    color: #000000;
    padding: 3px 11px;
    font-weight: normal;

    i {
      font-family: FontAwesome;
      font-weight: 400;
    }

    &:hover {
      background: white !important;
      color: black;
    }
  }

  @media only screen and (max-width: 991px) {
    iframe {
      max-width: 100%;
      height: auto;
    }

    .description h1 {
      font-size: 20px;
    }

    h1 {
      font-size: 20px;
      line-height: 25px;
    }
  }
}

@media screen and (min-width: 992px) {
  #app {
    min-height: 100vh;
  }
}
</style>
