<template>
    <div>
        <div class="dicta-header-frame" v-if="!mobileView">
            <common-header ref="embeddedHeader" 
                @menu-open="toggleDictaMenu" 
                @contact-open="toggleDictaContact" 
                :hebrew="hebrew" 
                :siteUrl="url">
            </common-header>
        </div>
        <div class="dicta-mobile-menu" v-if="mobileView">
            <mobile-menu ref="embeddedHeader" 
                @menu-open="toggleDictaMobileMenu" 
                @show-about-modal="showAboutModal" 
                :hebrew="hebrew" 
                :siteUrl="url">
            </mobile-menu>
        </div>
    </div>
</template>
<script>
let opener = null
import mobileMenu from "@/components/dicta-header/mobileMenu"
import commonHeader from "@/components/dicta-header/commonHeader"
export default {
    name: 'DictaHeader',
    components: {
        commonHeader, mobileMenu
    },
    data () {
    return {
        hebrew: true,
        url: '',
        mobileView: window.screen.width < 780       
       }
    },
    mounted() {
        window.addEventListener('message', this.embedMessageHandler)
        opener = window.parent
        if (opener) {
            opener.postMessage({ message: 'PAGE_LOADED' }, '*')            
        }
    },
    beforeDestroy() {
        window.removeEventListener('message', this.embedMessageHandler)
    },
    methods: {
        toggleDictaMobileMenu (menuOpen) {
            if (opener) {
                opener.postMessage({ message: 'MOBILE_MENU_TOGGLE', value: menuOpen }, '*')            
            }
        },
        showAboutModal (contactOpen) {
            if (opener) {
                opener.postMessage({ message: 'SHOW_MOBILE_ABOUT', value: contactOpen }, '*')            
            }   
        },
        toggleDictaMenu (menuOpen) {
            if (opener) {
                opener.postMessage({ message: 'MENU_TOGGLE', value: menuOpen }, '*')            
            }
        },
        toggleDictaContact (contactOpen) {
            if (opener) {
                opener.postMessage({ message: 'CONTACT_TOGGLE', value: contactOpen }, '*')            
            }   
        },
        embedMessageHandler(event) {
            if (event.data.message === 'HEADER_EMBEDDED') {
                if (event.data.data) {
                    this.hebrew = event.data.data.hebrew
                    this.url = event.data.data.url                    
                }
            }
            
        }
    }
}
</script>