<template>
  <div class="footer" :class="{'he': hebrew}" :dir="hebrew ? 'rtl' : 'ltr'">
    <div class="container">
      <div class="footer-content">
        <a v-if="hebrew" class="footer-item" href="https://dicta.org.il/about" target="_blank">אודות</a>
        <a v-else class="footer-item" href="https://dicta.org.il/about?lang=en" target="_blank">About</a>
        <a class="footer-item" href="https://dicta.org.il/tos.html" target="_blank">{{hebrew ? 'תנאים ושרותים' : 'Terms of service'}}</a>
        <a href="https://www.facebook.com/dictatools" target="_blank"
           class="footer-item footer-icon"><i class="social-icon fab fa-facebook-f"></i></a>
        <a href="https://twitter.com/DictaTools?lang=he" target="_blank"
           class="footer-item footer-icon"><i class="social-icon fab fa-twitter"></i></a>
        <a href="https://www.youtube.com/channel/UC4ickfqPHtDMAKIGvMBMQyw"
           target="_blank" class="footer-item footer-icon"><i class="social-icon fab fa-youtube"></i></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tool-footer',
  props: ['hebrew'],
  data () {
    return {}
  },
  computed: {
  }
}
</script>
<style scoped>
  .footer {
    background-color: #f6f6f6;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
  .footer-content {
    flex-grow: 1;
    max-width: 1140px;
    display: flex;
    font-size: 13px;
    line-height: 23px;
  }
  .footer-item {
    margin: 0 11px 0 0;
    font-weight: 500;
  }
  .footer-icon {
    color: #f6f6f6;
    height: 23px;
    width: 23px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    background-color: black;
  }
  .footer-icon:hover {
    text-decoration: none;
    background-color: #4A5057;
  }
  a {
    color: black;
  }
  @media screen and ( max-width: 991px ) {
    .mobile-dropdown-header {
      flex-direction: row;
    }

    .media-left {
      margin-left: 5px;
    }

    .bottom-row {
      flex-direction: row;
    }
  }
</style>
