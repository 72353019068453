<template>
  <div class="updates">
    <div class="container">
        <div class="tools-heading text-center mt-0">{{hebrew ? 'עדכונים' : 'Updates'}}</div>
        <div class="blue-sep"></div>
        <div role="tablist" v-bind:class="{'eng' : !hebrew}" id="accordion">
          <b-card no-body class="mb-0" v-for="(update, index) in updateListItems" :key="index" >
            <b-card-header header-tag="header" class="p-0" role="tab">
              <b-button block href="#" v-b-toggle="'accordion-' + index" variant="background">
                <span>{{hebrew ? update.hebTitle : update.engTitle}} <span dir="ltr" v-if="update.date">[{{update.date}}]</span></span>
              </b-button>
            </b-card-header>
            <b-collapse :id="'accordion-'+index.toString()" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <div class="news-item-details" v-html="hebrew ? update.hebText : update.engText"></div>
                <a style="cursor:pointer" @click="setNewsItem(update.href)" class="text-primary mt-3 d-block">
                  {{hebrew? 'פתיחה בחלון חדש' : 'Open in new window'}}
                </a>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      <b-btn 
      variant="link" 
      class="bg-transparent d-block mt-2 mx-auto text-primary shadow-none p-0" 
      @click="showAllUpdates=!showAllUpdates">
      <span v-if="hebrew" class="text-underline">
        {{showAllUpdates ? 'הצג פחות' : 'הצג הכל'}}
      </span>
      <span v-else class="text-underline">
        {{showAllUpdates ? 'Show Less' : 'Show More'}}
      </span>
    </b-btn>        
    </div>
    
  </div>
</template>

<script>
  import { updates } from './updateList'

  
  export default {
  name: 'Updates',
  data() {
    return { 
      updates,
      showAllUpdates: false
    }
  },
  methods: {
    setNewsItem(href) {
      this.$store.commit('SET_NEWS_ITEM', href)
      this.$router.push({ path: href });
    }
  },
  computed: {
    hebrew () {
      return this.$settings.hebrew
    },
    updateListItems () {
      return this.showAllUpdates ? this.updates :  this.updates.slice(0, 5)
    }
  }
 }      
</script>
<style lang="scss" scoped>
  .updates {
    background: #d6ecff;
    padding: 30px 0;
    border-radius: 4px;
    a.btn {
      box-shadow: none;
      margin: 0 0 -1px 0;
      padding-top: 7px;
      padding-bottom: 7px;
      font-size: 16px;
      text-align: inherit;
      position: relative;
      text-decoration: none;
      &::after {
        font-family: FontAwesome;
        content: "\f068";
        position: absolute;
        @media only screen and (max-width: 991px) {
          top: 10px;
        }
        right: 15px;
      }
      &.collapsed {
        &::after {
          content: "\f067";
        }  
      }
      span {
        max-width: 90%;
        display: inline-block;
      }
    }
    a {
      text-decoration: underline;
    }
  }
  .he .updates a.btn::after {
    right: inherit;
    left: 15px;
  }
</style>
<style lang="scss">
  #app .news-item-details {
    img {
      max-width: 700px;
      @media only screen and (max-width: 991px) {
        max-width: 100%;
      }
      margin: auto;
      display: block;
    }
  }
</style>    