<template>
   <b-modal 
    @hidden="$emit('menu-closed')"
    no-fade 
    v-model="show" 
    id="tool-list" 
    hide-footer
    body-class="p-0"
    dialog-class="m-0"
    :title="hebrew ? 'הכלים של דיקטה' : 'Dicta\'s Tools'" >
    <div class="dicta-tools-popup-items-container" :dir="hebrew ? 'rtl' : 'ltr'">
      <div class="dicta-tools" >
        <ul class="tool-list list-unstyled p-0 m-0">
          <li
            class="tool dicta-tools-item contact-link border-top border-bottom border-primary"
            @click="showContactModal = true"
            >
            <a
              id="mobile-contact-us"
              class="text-primary title"
            >
              {{ hebrew ? 'משוב' : 'Feedback' }}
            </a>
            <i class="far fa-envelope px-3 text-primary"></i>
          </li>
          <li class="tool dicta-tools-item" v-for="(tool, index) in dictatools" :key="index">
            <a
              :href="tool.hasOwnProperty('href') ? tool.href : (hebrew ? tool.hebHref : tool.engHref)"
              class="tool-link"
              target="_blank"
            >
              <img class="logo" alt="logo" :src="tool.logo" />
              <div class="tool-description">
                <div>
                  <span v-bind:title="tool.hebSubtitle" class="title">{{hebrew ? tool.hebTitle : tool.engTitle}}</span>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="dicta-other-buttons">
        <ul class="list-unstyled p-0 m-0" :class="hebrew ? 'text-right' : 'text-left'">
          <li class="dicta-tools-item">
            <a
              class="text-body title"
              :href="'https://dicta.org.il/about' + (!hebrew ? '?lang=en' : '')"
              target="_blank"
            >{{ hebrew ? 'אודות דיקטה' : 'About Us' }}</a>
          </li>
          <li class="dicta-tools-item">
            <a class="text-body title" href="https://dicta.org.il/tos" target="_blank">{{hebrew ? 'תנאים ושרותים' : 'Terms of service'}}</a>
          </li>
          <li class="dicta-tools-item" v-if="englishSupported">
            <a class="text-body title" @click="changeLanguage">{{ hebrew ? 'English' : 'עברית' }}</a>
          </li>
        </ul>
      </div>
      <div class="dicta-tools-popup-footer mt-5">
        <a href="https://www.facebook.com/dictatools" target="_blank" class="footer-item footer-icon rounded-circle">
          <i class="social-icon fab fa-facebook-f"></i>
        </a>
        <a
          href="https://twitter.com/DictaTools"
          target="_blank"
          class="footer-item footer-icon rounded-circle"
        >
          <i class="social-icon fab fa-twitter"></i>
        </a>
        <a
          href="https://www.youtube.com/channel/UC4ickfqPHtDMAKIGvMBMQyw"
          target="_blank"
          class="footer-item footer-icon rounded-circle"
        >
          <i class="social-icon fab fa-youtube"></i>
        </a>
      </div>
    </div>
    <contact-us :hebrew="hebrew" :isMobile="true" :siteUrl="siteUrl" v-model="showContactModal" @mobile-modal-closed="$emit('menu-closed')"></contact-us>
    <!-- <mobile-contact-us :hebrew="hebrew" v-model></mobile-contact-us> -->
   </b-modal>
</template>

<script>
import { tools } from './toolList'
//import MobileContactUs from './mobileContactUs'
import ContactUs from './contactUs'
export default {
  name: 'DictaToolsPopup',
  components: { ContactUs },
  data () {
    return {
      tools,
      showContactModal: false
    }
  },
  props: {
    siteUrl: {},
    value: {},
    hebrew: { },
    englishSupported: {
      default: true
    },
    customLinks: {
      type: Array,
      default: () =>
        []
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    dictatools () {
      return this.customLinks.concat(this.tools)
    },
  },
  methods: {
    changeLanguage () {
      this.$emit('change-language')
    }
  }
}
</script>

<style scoped lang="scss">
/* DocumentSettingsGenre-specific CSS goes here */



.tool-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.tool {
  flex: 0 1 100%;
  list-style: none;
  text-align: start;
  background-color: white;
}

/* holds both the logo and text */
.tool-link {
  display: flex;
  color: black;
  text-decoration: none;
  align-items: center;
}
.logo {
  display: inline-block;
  margin: 2px 2px;
  height: 40px;
  width: auto;
  background-color: white;
  align-self: flex-start;
}
.description {
  display: inline-block;
}
.title {
  font-size: 18px;
  padding: 2px 5px;
}
[dir="rtl"]{
  .dicta-tools-item {
    text-align: right;
    &::after {
      left: 15px;
      content: "\f104";
    }
  }
}
[dir="ltr"]{
  .dicta-tools-item {
    &::after {
      right: 15px;
      content: "\f105";
    }
  }
}
.dicta-tools-item {
  min-height: 59px;
  border-bottom: solid 1px #d8d8d8;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  &::after {
    top: 14px;
    font-size: 20px;
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
  }
  &.contact-link {
    background-color: #d6ecff;
    i {
      font-size: 24px;
    }
    &::after {
      content: '';
    }
  }
  span{
    display: block;
  }
  a{
    padding: 7px 10px;
    width: 100%;
   }
}

.dicta-tools-popup-header {
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #d8d8d8;
}

.v-center {
  display: flex;
  align-items: center;
}

.left-items {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.dicta-tools-popup-footer {
  height: 50px;
  display: flex;
  align-items: center;
}
.footer-item {
  margin: 0 0 0 11px;
  font-weight: 500;
}
[dir="rtl"]{
 .footer-item {
    margin: 0 11px 0 0;
 }
}
.footer-icon {
  color: #f6f6f6;
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  font-size: 16px;
}
.footer-icon:hover {
  text-decoration: none;
  background-color: #4a5057;
}
</style>
