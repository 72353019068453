<template>
  <header id="header">
    <div class="top-header">
      <div
        id="dropdown-header"
        class="dropdown"
        v-bind:class="{ closed: !showForm }"
      >
        <Form></Form>
        <a @click="showForm = false" id="cancel-above" class="cancel"
          ><img src="../assets/ic_cancel.png" alt="סגירת טופס"
        /></a>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-5 add">
            <div id="topLogo" class="logo">
              <router-link class="text-white" to="/">DICTA</router-link>
            </div>
            <p class="text-white d-none d-lg-inline-block">
              {{
                hebrew
                  ? "כלים דיגיטליים לעיבוד טקסטים עבריים"
                  : "Analytical tools for Hebrew texts"
              }}
            </p>
          </div>
          <div
            class="col-7"
            id="top-actions"
            v-bind:class="{ 'text-left': hebrew, 'text-right': !hebrew }"
          >
            <div class="btn-group">
              <a
                class="text-white mx-3 mt-2 d-none d-sm-inline-block"
                @click="changeLanguage"
                >{{ hebrew ? "English" : "עברית" }}</a
              >
              <a
                class="text-white mx-3 mt-2 d-inline-block d-sm-none"
                @click="changeLanguage"
                >{{ hebrew ? "En" : "He" }}</a
              >
              <a
                @click="showForm = !showForm"
                id="contactUsButton"
                class="bt bt-sm contact-button"
              >
                <p>{{ hebrew ? "יצירת קשר" : "Contact Us" }}</p>
              </a>
            </div>
            <!--/Dropdown primary-->
          </div>
        </div>
      </div>
    </div>

    <div class="navigation" id="navigation">
      <div class="container">
        <ul class="p-0 m-0">
          <li>
            <router-link class="text-body" to="/"
              ><i class="fas fa-home d-none d-md-inline"></i>
              {{ hebrew ? "ראשי" : "Home" }}</router-link
            >
          </li>
          <li>
            <router-link class="text-body" to="tools">{{
              hebrew ? "הכלים שלנו" : "Our Tools"
            }}</router-link>
          </li>
          <li>
            <router-link class="text-body" to="about">{{
              hebrew ? "אודות" : "About Dicta"
            }}</router-link>
          </li>
          <li>
            <router-link class="text-body" to="publications">{{
              hebrew ? "פרסומים" : "Publications"
            }}</router-link>
          </li>
          <li>
            <router-link class="text-body" to="developers">{{
              hebrew ? "מפתחים" : "Developers"
            }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import Form from "../components/form"
export default {
  name: "Header",
  data() {
    return {
      showForm: false,
    }
  },
  components: {
    Form,
  },
  mounted() {
    if (this.$route.query.contact === "open") {
      this.$store.commit("TOGGLE_CONTACT_FORM", true)
    }
  },
  methods: {
    changeLanguage() {
      this.$emit("ChangeLanguage")
    },
    /*  toggleForm(val) {
      this.$store.commit("TOGGLE_CONTACT_FORM", val)
    }, */
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
  },
  watch: {},
}
</script>
<style lang="scss" scoped>
a {
  &:hover {
    text-decoration: none;
  }
}

.top-header {
  height: 60px;
  position: relative;
  padding: 9px 0;
  background-color: #007ee5;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.4);
  direction: ltr;

  .add {
    padding-top: 5px;

    .logo {
      width: 88px;
      height: 26px;
      font-family: "Arimo", sans-serif;
      font-size: 30px;
      line-height: 30px;
      display: inline-block;
      vertical-align: middle;
    }

    p {
      margin: 0;
      font-size: 16px;
      line-height: 19px;
      position: relative;
      top: 5px;
      padding: 0 14px;
      font-family: "Arimo", sans-serif;
    }
  }

  .bt {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    width: 96px;
    height: 40px;
    border-radius: 2px;
    background-color: #ffa900;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
    color: white;
    text-align: center;
    padding-top: 7px;

    &:hover {
      color: white;
    }
  }

  .btn-group {
    .text-white {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.navigation {
  direction: ltr;
  font-size: 14px;
  li {
    display: inline-block;
    margin: 7px 3px;

    a {
      padding: 2px 6px;
      border-radius: 2px;
    }
  }
}

.he {
  .navigation,
  .top-header {
    direction: rtl;
  }
}

.navigation .router-link-exact-active,
.navigation li:hover,
.navigation li.active {
  background-color: #e0e0e0;
}

.navigation li a {
  color: black;
}

.navigation li a:hover {
  color: black;
}

.dropdown {
  height: 350px;
  z-index: 1;

  @media only screen and (max-width: 1024px) {
    height: 100%;
    top: 0;
    overflow: hidden;
    position: fixed;
    left: 0;
    z-index: 99;
  }

  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  bottom: auto;
  border: none;
  box-shadow: 0 22px 29px 0 rgba(0, 0, 0, 0.5);
  position: absolute;
  background: white;
  width: 100%;
  left: 0;
  top: 60px;

  &.closed {
    height: 0;
    overflow-y: hidden;
  }
}
</style>
<style lang="scss">
#dropdown-header {
  .holder:after {
    top: -20px;
    left: 95%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .holder:before {
    border: none;
  }

  .holder:after {
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: 2px;
  }

  .cancel {
    cursor: pointer;
    position: absolute;
    top: 28px;
    left: 37px;
    top: 15px;
    z-index: 1;

    @media only screen and (max-width: 1024px) {
      left: 15px;
    }
  }
}

.he #dropdown-header {
  .holder:after {
    left: 3.3%;
    right: inherit;
  }

  .cancel {
    left: inherit;
    right: 37px;

    @media only screen and (max-width: 1024px) {
      right: 15px;
    }
  }
}
</style>
